var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0"
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.registrarChofer.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "col-12 col-sm-12" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _vm._m(1),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _vm._v(
                                        "\n                                                                    COOPERATIVA DE TRANSPORTE\nNACIONAL E INTERNACIONAL\n  CRUZ DEL NORTE R.L. \n                                                                    "
                                      ),
                                      _c(
                                        "P",
                                        {
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _c("small", [
                                            _vm._v("Cel.: 68170889 - 76871401 ")
                                          ]),
                                          _c("br"),
                                          _vm._v(" "),
                                          _c("small", [
                                            _vm._v("Riberalta-Beni-Bolivia")
                                          ]),
                                          _c("br"),
                                          _vm._v(" "),
                                          _c("small", [
                                            _vm._v("NIT: 506962021")
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _vm._m(2),
                                  _vm._v(" "),
                                  _c(
                                    "table",
                                    {
                                      staticClass: "table",
                                      staticStyle: { float: "left" }
                                    },
                                    [
                                      _vm._m(3),
                                      _vm._v(" "),
                                      _c("tbody", [
                                        _c("tr", [
                                          _c("td", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.guia.lugar,
                                                  expression: "guia.lugar"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "lugar",
                                                readonly: "",
                                                required: ""
                                              },
                                              domProps: {
                                                value: _vm.guia.lugar
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.guia,
                                                    "lugar",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.guia.fecha,
                                                  expression: "guia.fecha"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "date",
                                                id: "fecha",
                                                readonly: "",
                                                required: ""
                                              },
                                              domProps: {
                                                value: _vm.guia.fecha
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.guia,
                                                    "fecha",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ])
                                        ])
                                      ])
                                    ]
                                  )
                                ])
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "col-12 col-sm-12" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "origen" } }, [
                                    _vm._v("Remitente:")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.guia.remitente,
                                        expression: "guia.remitente"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "remitente",
                                      placeholder: "Ingrese nombre remitente",
                                      required: ""
                                    },
                                    domProps: { value: _vm.guia.remitente },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.guia,
                                          "remitente",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.errors.remitente
                                    ? _c(
                                        "span",
                                        { staticClass: "text-danger" },
                                        [_vm._v(_vm._s(_vm.errors.remitente))]
                                      )
                                    : _vm._e()
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "destino" } }, [
                                    _vm._v("Telefono:")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.guia.telefono,
                                        expression: "guia.telefono"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "number",
                                      id: "telefono",
                                      placeholder: "ingrese su telefono",
                                      required: ""
                                    },
                                    domProps: { value: _vm.guia.telefono },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.guia,
                                          "telefono",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "col-12 col-sm-12" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "origen" } }, [
                                    _vm._v("Consignatario:")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.guia.consignatario,
                                        expression: "guia.consignatario"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "consignatario",
                                      placeholder: "Ingrese el consignatario",
                                      required: ""
                                    },
                                    domProps: { value: _vm.guia.consignatario },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.guia,
                                          "consignatario",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "destino" } }, [
                                    _vm._v("Telf:")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.guia.telefono2,
                                        expression: "guia.telefono2"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "number",
                                      id: "telefono",
                                      placeholder: "ingrese su telefono",
                                      required: ""
                                    },
                                    domProps: { value: _vm.guia.telefono2 },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.guia,
                                          "telefono2",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "col-12 col-sm-12" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "origen" } }, [
                                    _vm._v("Direccion:")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.guia.direccion,
                                        expression: "guia.direccion"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "direccion",
                                      placeholder: "Ingrese su direccion",
                                      required: ""
                                    },
                                    domProps: { value: _vm.guia.direccion },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.guia,
                                          "direccion",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "destino" } }, [
                                    _vm._v("Destino: ")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.guia.destino,
                                          expression: "guia.destino"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { id: "lugar", required: "" },
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.guia,
                                            "destino",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        { attrs: { disabled: "", value: "" } },
                                        [_vm._v("Seleccione un departamento")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "Chuquisaca" } },
                                        [_vm._v("Chuquisaca")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "La Paz" } },
                                        [_vm._v("La Paz")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "Cochabamba" } },
                                        [_vm._v("Cochabamba")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "Oruro" } },
                                        [_vm._v("Oruro")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "Potosí" } },
                                        [_vm._v("Potosí")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "Tarija" } },
                                        [_vm._v("Tarija")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "Santa Cruz" } },
                                        [_vm._v("Santa Cruz")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "Beni" } },
                                        [_vm._v("Beni")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { attrs: { value: "Pando" } },
                                        [_vm._v("Pando")]
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c(
                              "form",
                              {
                                on: {
                                  submit: function($event) {
                                    $event.preventDefault()
                                    return _vm.agregarProducto.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [
                                _c("label", [
                                  _vm._v(
                                    "\n                                                    N°Bultos:\n                                                    "
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.nbultos,
                                        expression: "nbultos"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text", required: "" },
                                    domProps: { value: _vm.nbultos },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.nbultos = $event.target.value
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("label", [
                                  _vm._v(
                                    "\n                                                    Descripcion:\n                                                    "
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.descripcion,
                                        expression: "descripcion"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text", required: "" },
                                    domProps: { value: _vm.descripcion },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.descripcion = $event.target.value
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("label", [
                                  _vm._v(
                                    "\n                                                    Kilos:\n                                                    "
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.kilos,
                                        expression: "kilos"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text" },
                                    domProps: { value: _vm.kilos },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.kilos = $event.target.value
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("label", [
                                  _vm._v(
                                    "\n                                                    Total:\n                                                    "
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.total,
                                        expression: "total"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "text", required: "" },
                                    domProps: { value: _vm.total },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.total = $event.target.value
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.guia_id,
                                        expression: "guia_id"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { type: "hidden" },
                                    domProps: { value: _vm.guia_id },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.guia_id = $event.target.value
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    attrs: { type: "submit" }
                                  },
                                  [_vm._v("Agregar producto")]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "table",
                              {
                                staticClass:
                                  "table table-striped table-bordered table-hover",
                                attrs: { width: "100%" }
                              },
                              [
                                _vm._m(4),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.guia.productos, function(
                                    producto,
                                    index
                                  ) {
                                    return _c("tr", { key: index }, [
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(producto.nbultos))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(producto.descripcion))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(producto.kilos))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(producto.total))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-danger",
                                            on: {
                                              click: function($event) {
                                                return _vm.eliminarProducto(
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                                    Eliminar\n                                                                "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _vm._m(5),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "col-12 col-sm-12" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "origen" } }, [
                                    _vm._v("Total a Pagar:")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.guia.total_pagar,
                                        expression: "guia.total_pagar"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "total_pagar",
                                      placeholder: "Ingrese un monto"
                                    },
                                    domProps: { value: _vm.guia.total_pagar },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.guia,
                                          "total_pagar",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "destino" } }, [
                                    _vm._v("A Cuenta:")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.guia.acuenta,
                                        expression: "guia.acuenta"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "acuenta",
                                      placeholder: "ingrese un monto"
                                    },
                                    domProps: { value: _vm.guia.acuenta },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.guia,
                                          "acuenta",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-6 col-lg-6 mt-3" },
                                  [
                                    _c(
                                      "label",
                                      { attrs: { for: "por_pagar" } },
                                      [_vm._v("Por Pagar:")]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "por_pagar",
                                        readonly: ""
                                      },
                                      domProps: { value: _vm.porPagar }
                                    })
                                  ]
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("label", { staticClass: "text-body" }, [
                              _vm._v("Choferes")
                            ]),
                            _vm._v(" "),
                            _c(
                              "fieldset",
                              { staticClass: "form-group mb-3 d-flex" },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.guia.chofer_id,
                                        expression: "guia.chofer_id"
                                      }
                                    ],
                                    staticClass:
                                      "js-example-basic-single js-states form-control bg-transparent",
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            _vm.guia,
                                            "chofer_id",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function($event) {
                                          return _vm.appendChild(
                                            $event,
                                            "select"
                                          )
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v("Selecciona una opcion:")
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(_vm.guia_dropdowns, function(
                                      chofer
                                    ) {
                                      return chofer.status == 1
                                        ? _c(
                                            "option",
                                            {
                                              key: chofer.id,
                                              domProps: {
                                                value: chofer.id,
                                                selected: chofer.id
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(chofer.nombre) +
                                                  "\n                                                        "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    })
                                  ],
                                  2
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.errors.has("chofer_id")
                              ? _c("small", {
                                  staticClass: "form-text text-danger",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.errors.get("chofer")
                                    )
                                  }
                                })
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm._m(6)
                        ]
                      )
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "card card-custom gutter-b bg-transparent shadow-none border-0"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-header align-items-center  border-bottom-dark px-0"
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body"
                    },
                    [
                      _vm._v(
                        "\n                                                Agregar Nueva Guia\n                                            "
                      )
                    ]
                  )
                ])
              ]
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "text-align": "center" } }, [
      _c(
        "span",
        { staticClass: "brand-text", staticStyle: { "text-align": "center" } },
        [
          _c("img", {
            staticStyle: { height: "110px", "align-content": "center" },
            attrs: { alt: "Logo", src: "/assets/images/misc/guia.png" }
          })
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c(
        "small",
        { staticStyle: { display: "inline-block", "margin-left": "50px" } },
        [_vm._v("SERVICIO DE PUERTA A PUERTA")]
      ),
      _c("br"),
      _vm._v(" "),
      _c(
        "small",
        { staticStyle: { display: "inline-block", "margin-left": "50px" } },
        [_vm._v("COTIZACIONES A DOMICILIO SIN NINGUN COMPROMISO")]
      ),
      _c("br"),
      _vm._v(" "),
      _c(
        "small",
        { staticStyle: { display: "inline-block", "margin-left": "50px" } },
        [_vm._v("SERVICIO DE TRANSPORTE")]
      ),
      _c("br"),
      _vm._v(" "),
      _c(
        "small",
        { staticStyle: { display: "inline-block", "margin-left": "50px" } },
        [_vm._v("DE CARGA EN GENERAL")]
      ),
      _c("br"),
      _vm._v(" "),
      _c(
        "small",
        { staticStyle: { display: "inline-block", "margin-left": "50px" } },
        [_vm._v("MUDANZAS, EMBALAJES")]
      ),
      _c("br"),
      _vm._v(" "),
      _c(
        "small",
        { staticStyle: { display: "inline-block", "margin-left": "50px" } },
        [_vm._v("ALMACENAMIENTO Y LOCAL")]
      ),
      _c("br"),
      _vm._v(" "),
      _c(
        "small",
        { staticStyle: { display: "inline-block", "margin-left": "50px" } },
        [_vm._v("LAS 24 HORAS")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { width: "5%" } }, [_vm._v("Lugar")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "5%" } }, [_vm._v("Fecha")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "text-center table-primary1" }, [
        _c("th", { attrs: { width: "7%" } }, [_vm._v("N° BULTOS")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "19%" } }, [_vm._v("DESCRICION")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "35%" } }, [_vm._v("KILOS")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "5%" } }, [_vm._v("TOTAL")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "3%" } }, [_vm._v("Acción")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-8" }, [
          _c("div", { staticClass: "form-group row" }, [
            _c("div", {
              staticClass: "col-sm-11",
              staticStyle: { "border-right": "1px solid white" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-1" })
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Registrar Guia")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }