<template>
<!--begin::Header-->
    <div id="tc_header" class="header header-fixed">
        <!--begin::Container-->
        <div class="container-fluid d-flex align-items-stretch justify-content-between">
            <!--begin::Header Menu Wrapper-->
            <div class="header-menu-wrapper header-menu-wrapper-left" id="tc_header_menu_wrapper">
                <!--begin::Header Menu-->
                <div id="tc_header_menu" class="header-menu header-menu-mobile header-menu-layout-default">
                    <!--begin::Header Nav-->
                    <ul class="menu-nav">

                            <li class="menu-item menu-item-open menu-item-here menu-item-submenu menu-item-rel menu-item-open menu-item-here menu-item-active p-0"
                            data-menu-toggle="click" aria-haspopup="true" @click="setBurgerMenu()">
                            <!--begin::Toggle-->
                            <div class="btn  btn-clean btn-dropdown mr-0 p-0" id="tc_aside_toggle">
                                <span class="svg-icon svg-icon-xl svg-icon-primary">

                                    <svg width="24px" height="24px" viewBox="0 0 16 16" class="bi bi-list"
                                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                                    </svg>
                                </span>
                            </div>
                            <!--end::Toolbar-->
                        </li>

                    </ul>
                    <!--end::Header Nav-->
                </div>
                <!--end::Header Menu-->
            </div>
            <!--end::Header Menu Wrapper-->
            <!--begin::Topbar-->
            
            <div class="topbar">
                <!-- <div class="posicon d-lg-block d-none" v-if="$parent.permissions.includes('pos')">
                    <router-link to="/admin/pos" class="btn btn-primary white mr-2">POS</router-link>
                </div> -->
                <!-- <div class="dropdown" :class="{ 'show': showNotifications }">
                    <div class="topbar-item" data-toggle="dropdown" data-display="static" :aria-expanded="showNotifications" @click="toggleNotifications">
                        <div class="btn btn-icon w-auto btn-clean d-flex align-items-center pr-1 pl-3">
                            <span class="svg-icon svg-icon-xl svg-icon-primary">
                                <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-bell" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 16a2 2 0 0 0 1.985-1.75H6.015A2 2 0 0 0 8 16zm.35-14.444a1 1 0 0 0-1.8 0 5.987 5.987 0 0 0-2.826 5.13c-.032.276-.138.538-.263.804-.16.337-.337.676-.337 1.268v.535l-1.186.914A1 1 0 0 0 3.537 13h8.926a1 1 0 0 0 .759-1.663l-1.186-.914v-.535c0-.592-.177-.93-.337-1.268a4.12 4.12 0 0 1-.263-.804A5.987 5.987 0 0 0 8.35 1.556z"/>
                                </svg>
                                <span class="badge badge-danger" v-if="notifications.length > 0">{{ notifications.length }}</span>
                            </span>
                        </div>
                    </div>

                    <div class="dropdown-menu dropdown-menu-right" :class="{ 'show': showNotifications }" style="min-width: 250px;">
                        <div v-if="notifications.length === 0" class="dropdown-item text-center">No hay notificaciones</div>
                        <div v-else>
                            <a href="#" class="dropdown-item" v-for="(notification, index) in notifications" :key="index" @click="markAsRead(notification.id, index)">
                                <div class="mr-3">
                                    <img :src="notification.user.avatar ? notification.user.avatar : '/images/jugador.png'" alt="user-image" 
                                        class="rounded-circle" style="width: 40px; height: 40px;">
                                </div>

                                <div class="flex-grow-1">
                                    <p class="mb-0 font-weight-bold">{{ notification.user.name }} {{ notification.user.first_name }}</p>
                                    <small class="text-muted">{{ notification.message }}</small>
                                    <div class="ml-auto">
                                        <small class="text-muted">{{ formatDate(notification.created_at) }}</small>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div> -->
                <div class="dropdown" :class="{ 'show': showNotifications }">
                    <div class="topbar-item" data-toggle="dropdown" data-display="static" :aria-expanded="showNotifications" @click="toggleNotifications">
                        <div class="btn btn-icon w-auto btn-clean d-flex align-items-center pr-1 pl-3">
                            <span class="svg-icon svg-icon-xl svg-icon-primary">
                                <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-bell" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 16a2 2 0 0 0 1.985-1.75H6.015A2 2 0 0 0 8 16zm.35-14.444a1 1 0 0 0-1.8 0 5.987 5.987 0 0 0-2.826 5.13c-.032.276-.138.538-.263.804-.16.337-.337.676-.337 1.268v.535l-1.186.914A1 1 0 0 0 3.537 13h8.926a1 1 0 0 0 .759-1.663l-1.186-.914v-.535c0-.592-.177-.93-.337-1.268a4.12 4.12 0 0 1-.263-.804A5.987 5.987 0 0 0 8.35 1.556z"/>
                                </svg>
                                <span class="badge badge-danger" v-if="unreadNotifications.length > 0">{{ unreadNotifications.length }}</span>
                            </span>
                        </div>
                    </div>

                    <div class="dropdown-menu dropdown-menu-right" :class="{ 'show': showNotifications }" style="min-width: 250px;">
                        <!-- Notificaciones No Leídas -->
                        <div v-if="unreadNotifications.length === 0" class="dropdown-item text-center">No hay notificaciones nuevas</div>
                        <div v-else>
                            <h6 class="dropdown-header">Nuevas Notificaciones</h6>
                            <a href="#" class="dropdown-item" v-for="(notification, index) in unreadNotifications" :key="index" @click="markAsRead(notification.id, index)">
                                <div class="d-flex align-items-start">
                                    <img :src="notification.user.avatar ? notification.user.avatar : '/images/jugador.png'" alt="user-image" class="rounded-circle mr-3" style="width: 40px; height: 40px;">
                                    <div>
                                        <p class="mb-0 font-weight-bold">{{ notification.user.name }} {{ notification.user.first_name }}</p>
                                        <small class="text-muted">{{ notification.message }}</small>
                                        <small class="text-muted d-block">{{ formatDate(notification.created_at) }}</small>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <!-- Notificaciones Leídas -->
                        <div v-if="readNotifications.length > 0">
                            <h6 class="dropdown-header">Notificaciones Leídas</h6>
                            <a href="#" class="dropdown-item" v-for="(notification, index) in readNotifications" :key="'read-' + index">
                                <div class="d-flex align-items-start">
                                    <img :src="notification.user.avatar ? notification.user.avatar : '/images/jugador.png'" alt="user-image" class="rounded-circle mr-3" style="width: 40px; height: 40px;">
                                    <div>
                                        <p class="mb-0 font-weight-bold">{{ notification.user.name }} {{ notification.user.first_name }}</p>
                                        <small class="text-muted">{{ notification.message }}</small>
                                        <small class="text-muted d-block">{{ formatDate(notification.created_at) }}</small>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>


                <div class="posicon d-lg-block d-none">
                    <div class="dropdown" :class="{ 'show': showdropdown }">
								<div class="topbar-item" data-toggle="dropdown" data-display="static" :aria-expanded="showdropdown" @click="showDropDown()">
									<div class="btn btn-icon w-auto btn-clean d-flex align-items-center pr-1 pl-3">
										<span class="text-dark-50 font-size-base d-none d-xl-inline mr-3">
                                            {{ username }}</span>
										<span class="symbol symbol-35 symbol-light-success" >
											<span class="symbol-label font-size-h5 " >
												<svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
													<path fill-rule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
												</svg>
											</span>
										</span>
									</div>
								</div>

								<div class="dropdown-menu dropdown-menu-right" :class="{ 'show': showdropdown }" style="min-width: 150px;">

									<router-link to="/admin/profile" class="dropdown-item">
										<span class="svg-icon svg-icon-xl svg-icon-primary mr-2">
											<svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
												<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
												<circle cx="12" cy="7" r="4"></circle>
											</svg>
										</span>
										Editar Perfil
									</router-link>

									<a href="#" class="dropdown-item" @click="logOut()">
										<span class="svg-icon svg-icon-xl svg-icon-primary mr-2">
											<svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-power">
												<path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
												<line x1="12" y1="2" x2="12" y2="12"></line>
											</svg>
										</span>
										Logout
									</a>
								</div>

							</div>
                </div>

            </div>
            <!--end::Topbar-->
        </div>
        <!--end::Container-->
    </div>
    <!--end::Header-->
</template>



<script>
export default {
   data(){
       return {
           showdropdown:false,
           showNotifications: false,
           username:"",
           notifications: [],
           unreadNotifications: [], // No leídas
           readNotifications: []
       }
       
   },
    methods: {
        formatDate(date) {
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
            return new Date(date).toLocaleDateString('es-ES', options);  // Formato en español
        },
        logOut() {
            this.$parent.loading = true;
            let page_url = "/api/admin/logout";

            axios.post(page_url,{},this.token).then(res => {
                this.orders = res.data.data;
                setTimeout(() => { this.$router.push('/admin/login'); }, 1000);
            })
        },
        setBurgerMenu(){
            
            var classList = document.body.classList.value.split(' ');
            console.log(classList);
            if(classList.indexOf('aside-minimize') === -1)
                document.body.classList.add('aside-minimize')
            else
                document.body.classList.remove('aside-minimize')

            this.$emit('setBurgerMenu');
        },
        showDropDown(){
            this.showdropdown = !this.showdropdown
        },
        toggleNotifications() {
            this.showNotifications = !this.showNotifications;
        },
        fetchNotifications() {
            let page_url = "/api/notification"; 
            axios.get(page_url, this.token).then(res => {
                console.log("notificaciones ", res.data.data);
                this.unreadNotifications = res.data.data;
                console.log("notificaciones ", this.unreadNotifications);
            });
        },

        viewNotifications() {
            let page_url = "/api/notificationleidas"; 
            axios.get(page_url, this.token).then(res => {
                console.log("notificaciones leidaas ", res.data.data);
                this.readNotifications = res.data.data;
                console.log("notificaciones leidas", this.readNotifications);
            });
        },
        markAsRead(id, index) {
    
        let page_url = `/api/notification/${id}/update`;

        axios.put(page_url, {}, this.token).then(res => {
            console.log("datos de actualizacion ", res.data);
            if (res.data.status === true) {
                this.notifications.splice(index, 1);
                this.notifications[index].read_at = new Date(); 
            }
        }).catch(err => {
            console.error('Error marcando la notificación como leída', err);
        });
    },
    },
    mounted() {
     
        var token = localStorage.getItem('token');
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        console.log(localStorage.getItem('name'));
        if(localStorage.getItem('name')){

            this.username = localStorage.getItem('name')
        }
        this.fetchNotifications();
    },
};
</script>
