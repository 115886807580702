<template>
    <div>
        <div class="d-flex flex-column-fluid">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card card-custom gutter-b bg-transparent shadow-none border-0">
                                    <div class="card-header align-items-center  border-bottom-dark px-0">
                                        <div class="card-title mb-0">
                                            <h3 class="card-label mb-0 font-weight-bold text-body">
                                                Agregar Nueva Guia
                                            </h3>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card card-custom gutter-b bg-white border-0">
                                    <div class="card-body">
                                        <form  @submit.prevent="registrarChofer">

                                            <div class="form-group">

                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <div style="text-align: center;">
                                                            <span class="brand-text" style="text-align: center;">
                                                                <img
                                                                    style="height: 110px; align-content: center;"
                                                                    alt="Logo"
                                                                    src="/assets/images/misc/guia.png"
                                                                />
                                                            </span>
                                                        </div>
                                                                <p style="text-align: center;">
                                                                    COOPERATIVA DE TRANSPORTE
NACIONAL E INTERNACIONAL
  CRUZ DEL NORTE R.L. 
                                                                    <P style="text-align: center;"><small>Cel.: 68170889 - 76871401 </small><br>
                                                                                                    <small>Riberalta-Beni-Bolivia</small><br>
                                                                                                    <!-- <small>Jose Jhonny Peña Rojas</small><br> -->
                                                                                                    <small>NIT: 506962021</small>
                                                                                                </P>
                                                                </p>

                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <p><small style="display: inline-block; margin-left: 50px;">SERVICIO DE PUERTA A PUERTA</small><br>
                                                                                           <small style="display: inline-block; margin-left: 50px;">COTIZACIONES A DOMICILIO SIN NINGUN COMPROMISO</small><br>
                                                                                           <small style="display: inline-block; margin-left: 50px;">SERVICIO DE TRANSPORTE</small><br>
                                                                                           <small style="display: inline-block; margin-left: 50px;">DE CARGA EN GENERAL</small><br>
                                                                                           <small style="display: inline-block; margin-left: 50px;">MUDANZAS, EMBALAJES</small><br>
                                                                                           <small style="display: inline-block; margin-left: 50px;">ALMACENAMIENTO Y LOCAL</small><br>
                                                                                           <small style="display: inline-block; margin-left: 50px;">LAS 24 HORAS</small></p>
                                                            <table class="table" style="float: left;">
                                                                <thead>
                                                                    <tr>
                                                                        <th width="5%">Lugar</th>
                                                                        <th width="5%">Fecha</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><input type="text" class="form-control" id="lugar" v-model="guia.lugar" readonly required></td>
                                                                        <td> <input type="date" class="form-control" id="fecha" v-model="guia.fecha" readonly required></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                       </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Remitente:</label>
                                                            <input type="text" class="form-control" id="remitente" v-model="guia.remitente" placeholder="Ingrese nombre remitente" required>
                                                            <span v-if="errors.remitente" class="text-danger">{{ errors.remitente }}</span>
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Telefono:</label>
                                                            <input type="number" class="form-control" id="telefono" v-model="guia.telefono" placeholder="ingrese su telefono" required>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Consignatario:</label>
                                                            <input type="text" class="form-control" id="consignatario" v-model="guia.consignatario" placeholder="Ingrese el consignatario" required>
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Telf:</label>
                                                            <input type="number" class="form-control" id="telefono" v-model="guia.telefono2" placeholder="ingrese su telefono" required>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Direccion:</label>
                                                            <input type="text" class="form-control" id="direccion" v-model="guia.direccion" placeholder="Ingrese su direccion" required>
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Destino: </label>
                                                            <select class="form-control" id="lugar"  v-model="guia.destino" required>
                                                                                <option disabled value="">Seleccione un departamento</option>
                                                                                <option value="Chuquisaca">Chuquisaca</option>
                                                                                <option value="La Paz">La Paz</option>
                                                                                <option value="Cochabamba">Cochabamba</option>
                                                                                <option value="Oruro">Oruro</option>
                                                                                <option value="Potosí">Potosí</option>
                                                                                <option value="Tarija">Tarija</option>
                                                                                <option value="Santa Cruz">Santa Cruz</option>
                                                                                <option value="Beni">Beni</option>
                                                                                <option value="Pando">Pando</option>
                                                                            </select>
                                                            <!-- <input type="text" class="form-control" id="destino" v-model="guia.destino" placeholder="ingrese el Destino" required> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- start table  -->
                                            <div class="col-sm-12">
                                                <form @submit.prevent="agregarProducto">
                                                    <label>
                                                    N°Bultos:
                                                    <input type="text" class="form-control" v-model="nbultos" required>
                                                    </label>
                                                    <label>
                                                    Descripcion:
                                                    <input type="text" class="form-control" v-model="descripcion" required>
                                                    </label>
                                                    <label>
                                                    Kilos:
                                                    <input type="text" class="form-control" v-model="kilos">
                                                    </label>
                                                    <label>
                                                    Total:
                                                    <input type="text" class="form-control" v-model="total" required>
                                                    <input type="hidden" class="form-control" v-model="guia_id" >
                                                    </label>
                                                    <button type="submit"  class="btn btn-primary">Agregar producto</button>
                                                </form>
                                                <table class="table table-striped table-bordered table-hover" width="100%">
                                                    <thead>
                                                        <tr class="text-center table-primary1">
                                                            <th width="7%">N° BULTOS</th>
                                                            <th width="19%">DESCRICION</th>
                                                            <th width="35%">KILOS</th>
                                                            <th width="5%">TOTAL</th>
                                                            <th width="3%">Acción</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(producto, index) in guia.productos" :key="index">
                                                            <td class="text-center">{{ producto.nbultos }}</td>
                                                            <td>{{ producto.descripcion }}</td>
                                                            <td>{{ producto.kilos }}</td>
                                                            <td class="text-center">{{ producto.total }}</td>
                                                            <td>
                                                                <button @click="eliminarProducto(index)" class="btn btn-danger">
                                                                    Eliminar
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <div class="col-sm-8">
                                                        <div class="form-group row">
                                                            <div class="col-sm-11"  style="border-right: 1px solid white;">
                                                            </div>
                                                            <div class="col-sm-1"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- en table  -->
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Total a Pagar:</label>
                                                            <input type="text" class="form-control" id="total_pagar" v-model="guia.total_pagar"  placeholder="Ingrese un monto">
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">A Cuenta:</label>
                                                            <input type="text" class="form-control" id="acuenta" v-model="guia.acuenta" placeholder="ingrese un monto">
                                                        </div>
                                                        <div class="col-6 col-lg-6 mt-3">
                                                            <label for="por_pagar">Por Pagar:</label>
                                                            <input type="text" class="form-control" id="por_pagar" :value="porPagar" readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                             <div class="col-md-12">
                                                <label class="text-body">Choferes</label>
                                                <fieldset class="form-group mb-3 d-flex">
                                                    <select class="js-example-basic-single js-states form-control bg-transparent" v-model='guia.chofer_id' @change="appendChild($event,'select')">
                                                        <option value="">Selecciona una opcion:</option>
                                                        <option v-for='chofer in guia_dropdowns' :value='chofer.id'
                                                            v-bind:selected="chofer.id"
                                                            v-bind:key="chofer.id"
                                                            v-if="chofer.status == 1"
                                                            >{{ chofer.nombre }}
                                                        </option>
                                                    </select>

                                                </fieldset>
                                                <small class="form-text text-danger" v-if="errors.has('chofer_id')" v-text="errors.get('chofer')"></small>
                                            </div>
                                            <div>
                                                <button type="submit" class="btn btn-primary">Registrar Guia</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>

  <script>
  import ErrorHandling from "./../../ErrorHandling";
  import axios from 'axios';
 
  export default {
  
    data() {
      return {
        activeItem: 'info-tab',
            guia: {
                numero: '',
                fecha: null,
                lugar: 'Riberalta-Beni',
                remitente : '',
                destino: '',
                consignatario: '',
                telefono: '',
                direccion: '',
                telefono2: '',
                total_pagar: '',
                acuenta: '',
                chofer_id : '',
                productos: [],
                total_pagar: 0
            },

        ClipboardEvent: '',
        request_method : '',
        token: [],
        guia_dropdowns:[],
      
        nbultos: '',
        descripcion: '',
        kilos: 0,
        total: 0,
        guia_id: '',


        errors: new ErrorHandling(),
        csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content")
      }
    },
    methods: {
        guiadropdowns(page_url) {
            this.$parent.loading = true;
            page_url = page_url || "/api/guias/create";
            axios.get(page_url, this.token).then(res => {
                this.guia_dropdowns = res.data.data;
                this.guia_id = res.data.numero.id + 1;
                console.log(res.data.numero);
            }).finally(() => (this.$parent.loading = false));
        },
        appendChild(e,select){
            this.findChild(e.target.value,select);
        },
        validateForm() {
        this.errors = {}; 


        if (!this.guia.remitente) {
          this.errors.remitente = 'El remitente es requerido.';
        }
        if (!this.guia.telefono) {
          this.errors.telefono = 'El teléfono es requerido.';
        }

        return Object.keys(this.errors).length === 0;
      },
      registrarChofer() {
        if (this.validateForm()) {

        var url = '/api/guias/add_guias';
        console.log(this.guia);
        this.request_method = 'post'

        axios[this.request_method](url, this.guia, this.token)
        .then(res => {
            console.log(res);
            if (res.data.status == true){
                this.$toaster.success(res.data.message);
                setTimeout(() => {
                    location.reload();
                }, 1000);
            } else {
                        this.$toaster.error(res.data.message)
                    }
        })
        .catch(error => {
					this.error_message = '';
					this.errors = new ErrorHandling();
					if (error.response.status == 422) {
						if(error.response.data.status == 'Error'){
                        this.$toaster.error(error.response.data.message)
						}
						else{
							this.errors.record(error.response.data.errors);
						}
					}
				}).finally(() => (this.$parent.loading = false));
        console.log('Guia registrada');
        this.numero = '';
        this.fecha = '';
        this.lugar = '';
        this.remitente = '';
        this.destino = '';
        this.consignatario = '';
        this.telefono = '';
        this.direccion = '';
        this.telefono2 = '';
        this.total_pagar = '';
        this.acuenta = '';
        this.chofer_id = '';

            }
      },
       agregarProducto() {
        if (!this.nbultos || !this.descripcion || !this.total) {
            alert("Todos los campos son obligatorios. Por favor, complete los campos faltantes.");
            return;
        }
        console.log("datos anteriores ", this.guia.productos);
        this.guia.productos.push({ nbultos: this.nbultos, descripcion: this.descripcion, kilos: this.kilos, total: this.total, guia_id: this.guia_id });
        this.nbultos = '';
        this.descripcion = '';
        this.kilos = '';
        this.total = '';
        this.updateTotalPagar(); 

      },
      updateTotalPagar() {
            this.guia.total_pagar = this.totalPagar;
        },
     
        eliminarProducto(index) {
      // Elimina el producto de la lista según el índice
      this.guia.productos.splice(index, 1);
    }

    },
    computed: { 
        porPagar() {
        const total = parseFloat(this.guia.total_pagar) || 0;
        const acuenta = parseFloat(this.guia.acuenta) || 0;
        return total - acuenta;
        },
        totalPagar() {
            return this.guia.productos.reduce((sum, producto) => {
                return sum + parseFloat(producto.total || 0);
            }, 0);
        },
    },
    watch: {
    guia: {
      deep: true,
      handler() {
        this.updateTotalPagar();
      },
    },
  },
    components: {
    },
    mounted() {
        this.guia.fecha = new Date().toISOString().substr(0, 10); 
        var token = localStorage.getItem('token');
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
         this.guiadropdowns();
         this.updateTotalPagar();

    }
  };
  </script>
