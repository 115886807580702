var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 " }, [
                _c("div", { staticClass: "row mb-3" }, [
                  _c("div", { staticClass: "col-md-4" }, [
                    _c("label", { attrs: { for: "startDate" } }, [
                      _vm._v("Fecha de Inicio:")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.startDate,
                          expression: "startDate"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "date", id: "startDate" },
                      domProps: { value: _vm.startDate },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.startDate = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-4" }, [
                    _c("label", { attrs: { for: "endDate" } }, [
                      _vm._v("Fecha de Fin:")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.endDate,
                          expression: "endDate"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "date", id: "endDate" },
                      domProps: { value: _vm.endDate },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.endDate = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-4 d-flex align-items-end" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          on: {
                            click: function($event) {
                              return _vm.fetchaccounts()
                            }
                          }
                        },
                        [_vm._v("Buscar por Fechas")]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0"
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: " table-responsive",
                            attrs: { id: "printableTable" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "dataTables_wrapper no-footer",
                                attrs: { id: "productaccountTable_wrapper" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "dataTables_length",
                                    attrs: { id: "productaccountTable_length" }
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Ver \n                                            "
                                      ),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.limit,
                                              expression: "limit"
                                            }
                                          ],
                                          attrs: {
                                            name: "productaccountTable_length",
                                            "aria-controls":
                                              "productaccountTable"
                                          },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.limit = $event.target
                                                  .multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              },
                                              function($event) {
                                                return _vm.fetchaccounts()
                                              }
                                            ]
                                          }
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "10" } },
                                            [_vm._v("10")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "25" } },
                                            [_vm._v("25")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "50" } },
                                            [_vm._v("50")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "100" } },
                                            [_vm._v("100")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "200" } },
                                            [_vm._v("200")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "500" } },
                                            [_vm._v("500")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "1000" } },
                                            [_vm._v("1000")]
                                          )
                                        ]
                                      ),
                                      _vm._v(" Entradas")
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dataTables_filter",
                                    attrs: { id: "productaccountTable_filter" }
                                  },
                                  [
                                    _c("label", [
                                      _vm._v("Buscar:"),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.searchParameter,
                                            expression: "searchParameter"
                                          }
                                        ],
                                        attrs: {
                                          type: "search",
                                          placeholder: "",
                                          "aria-controls": "productaccountTable"
                                        },
                                        domProps: {
                                          value: _vm.searchParameter
                                        },
                                        on: {
                                          keyup: function($event) {
                                            return _vm.fetchaccounts()
                                          },
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.searchParameter =
                                              $event.target.value
                                          }
                                        }
                                      })
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  {
                                    staticClass: "display dataTable no-footer",
                                    attrs: {
                                      id: "productaccountTable",
                                      role: "grid"
                                    }
                                  },
                                  [
                                    _c("thead", { staticClass: "text-body" }, [
                                      _c("tr", { attrs: { role: "row" } }, [
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy == "id"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy == "id"
                                                ? "sorting_desc"
                                                : "sorting",
                                            attrs: {
                                              tabindex: "0",
                                              "aria-controls":
                                                "productaccountTable",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-sort": "ascending",
                                              "aria-label":
                                                "ID: activate to sort column descending"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.sorting("id")
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                                N° Guia\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy == "name"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy == "nombre"
                                                ? "sorting_desc"
                                                : "sorting",
                                            attrs: {
                                              tabindex: "0",
                                              "aria-controls":
                                                "productaccountTable",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label":
                                                "account: activate to sort column ascending"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.sorting("nombre")
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                           Remitente\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy == "name"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy == "nombre"
                                                ? "sorting_desc"
                                                : "sorting",
                                            attrs: {
                                              tabindex: "0",
                                              "aria-controls":
                                                "productaccountTable",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label":
                                                "account: activate to sort column ascending"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.sorting("nombre")
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                           Consignatario\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                              Destino\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                           Telefono\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                            Fecha\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                            Estado\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                            Chofer\n                                                            "
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      {
                                        staticClass: "kt-table-tbody text-dark"
                                      },
                                      _vm._l(_vm.quations, function(quation) {
                                        return _c(
                                          "tr",
                                          {
                                            key: quation.id,
                                            staticClass:
                                              "kt-table-row kt-table-row-level-0 odd",
                                            attrs: { role: "row" }
                                          },
                                          [
                                            _c(
                                              "td",
                                              { staticClass: "sorting_1" },
                                              [
                                                _vm._v(
                                                  "\n                                                                00" +
                                                    _vm._s(quation.id) +
                                                    "\n                                                            "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(quation.remitente) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(
                                                    quation.consignatario
                                                  ) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(_vm._s(quation.destino))
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(quation.telefono2) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(quation.fecha) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(quation.caso) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(
                                                    quation.chofer
                                                      ? quation.chofer.nombre
                                                      : "Sin chofer asignado"
                                                  ) +
                                                  "\n                                                            "
                                              )
                                            ])
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "pagination pagination-sm m-0 float-right"
                                  },
                                  [
                                    _c(
                                      "li",
                                      {
                                        class: [
                                          {
                                            disabled: !_vm.pagination
                                              .prev_page_url
                                          }
                                        ]
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "page-link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                return _vm.fetchaccounts(
                                                  _vm.pagination.prev_page_url
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Previous")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "disabled" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "page-link text-dark",
                                          attrs: { href: "#" }
                                        },
                                        [
                                          _vm._v(
                                            "Page " +
                                              _vm._s(
                                                _vm.pagination.current_page
                                              ) +
                                              " of " +
                                              _vm._s(_vm.pagination.last_page)
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "page-item",
                                        class: [
                                          {
                                            disabled: !_vm.pagination
                                              .next_page_url
                                          }
                                        ]
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "page-link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                return _vm.fetchaccounts(
                                                  _vm.pagination.next_page_url
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Next")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "card card-custom gutter-b bg-transparent shadow-none border-0"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-header align-items-center  border-bottom-dark px-0"
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body"
                    },
                    [
                      _vm._v(
                        "\n                                        Reporte de Guias\n                                        "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "icons d-flex" })
              ]
            )
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }