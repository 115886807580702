<template>
    <div>
        <div class="d-flex flex-column-fluid">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card card-custom gutter-b bg-transparent shadow-none border-0">
                                    <div class="card-header align-items-center  border-bottom-dark px-0">
                                        <div class="card-title mb-0">
                                            <h3 class="card-label mb-0 font-weight-bold text-body">
                                                Agregar Nuevo Chofer
                                            </h3>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card card-custom gutter-b bg-white border-0">
                                    <div class="card-body">
                                        <form  @submit.prevent="registrarChofer">
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Nombre:</label>
                                                            <input type="text" class="form-control" id="nombre" v-model="driver.nombre"   @input="validateName($event, 'nombre')" placeholder="Ingrese su nombre completo" required pattern="[A-Za-z\s]+" title="El nombre solo debe contener letras y espacios.">

                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Apellido:</label>
                                                            <input type="text" class="form-control" id="apellido" v-model="driver.apellido"  @input="validateName($event, 'apellido')" placeholder="Ingrese su Apellido" required pattern="[A-Za-z\s]+">
                                                        </div>
                                                    </div>
                                                </div>	
                                            </div>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Direccion:</label>
                                                            <input type="text" class="form-control" id="direccion" v-model="driver.direccion" placeholder="Ingrese su direccion" required>
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Telefono:</label>
                                                            <input type="text" class="form-control" id="telefono" v-model="driver.telefono" @input="validateNumber($event, 'telefono')" placeholder="ingrese su telefono" required>
                                                        </div>
                                                    </div>
                                                </div>	
                                            </div>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">C.I.:</label>
                                                            <input type="text" class="form-control" id="ci" v-model="driver.ci" @input="validateNumber($event, 'ci')" placeholder="Ingrese su numero de carnet" required>
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Telf. Referencia:</label>
                                                            <input type="text" class="form-control" id="telreferencia" v-model="driver.telreferencia" @input="validateNumber($event, 'telreferencia')" placeholder="ingrese su telefono de referencia">
                                                        </div>
                                                    </div>
                                                </div>	
                                            </div>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">N° Placa:</label>
                                                            <input type="text" class="form-control" id="placa" v-model="driver.placa" placeholder="Ingrese su numero de placa" required>
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Tipo Vehiculo:</label>
                                                            <input type="text" class="form-control" id="vehiculo" v-model="driver.vehiculo" placeholder="ingrese su Vehiculo" required>
                                                        </div>
                                                    </div>
                                                </div>	
                                            </div>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Color Vehiculo:</label>
                                                            <input type="text" class="form-control" id="placa" v-model="driver.color"  placeholder="Ingrese el color de su vehiculo">
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Licencia:</label>
                                                            <input type="text" class="form-control" id="licencia" v-model="driver.licencia" placeholder="ingrese su Licencia">
                                                        </div>
                                                    </div>
                                                </div>	
                                            </div>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Email:</label>
                                                            <input type="text" class="form-control" id="email" v-model="driver.email" placeholder="Ingrese su correo electronico">
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Contraseña:</label>
                                                            <input type="password" class="form-control" id="password" v-model="driver.password" placeholder="****">
                                                        </div>
                                                    </div>
                                                </div>	
                                            </div>
                                            <div>
                                                <button type="submit" class="btn btn-primary" >Registrar chofer</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import ErrorHandling from "./../../ErrorHandling";
  export default {
    data() {
      return {
        activeItem: 'info-tab',
            driver: {
                nombre: '',
                apellido: '',
                ci : '',
                direccion: '',
                telefono: '',
                licencia : '',
                telreferencia: '',
                placa: '',
                vehiculo: '',
                color: '',
                email : '',
                password : ''
            },
            ClipboardEvent: '',
            nameError: '',
        request_method : '',
        token: [],
        errors: new ErrorHandling(),
        csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content")
      }
    },
    methods: {
        validateName(event, field) {
            const value = event.target.value;
            const regex = /^[A-Za-z\s]*$/;

            if (!regex.test(value)) {
                this.driver[field] = value.replace(/[^A-Za-z\s]/g, '');
            }
        },
        validateNumber(event, field) {
            const value = event.target.value;
            const regex = /^[0-9]*$/;
            if (!regex.test(value)) {
                this.driver[field] = value.replace(/[^0-9]/g, '');
            }
        },
      registrarChofer() {
        var url = '/api/drivers/add_drivers';
        this.request_method = 'post'
        console.log(this.driver);
        axios[this.request_method](url, this.driver, this.token)
        .then(res => {
            if (res.data.status == true){
                this.$toaster.success(res.data.message);
                this.driver.nombre = '';
                this.driver.apellido = '';
                this.driver.ci = '';
                this.driver.direccion = '';
                this.driver.telefono = '';
                this.driver.licencia = '';
                this.driver.telreferencia = '';
                this.driver.placa = '';
                this.driver.vehiculo = '';
                this.driver.color = '';
                this.driver.email = '';
                this.driver.password = '';
                setTimeout(() => { this.$router.push('/admin/add-drivers'); }, 1000);
            } else {
                        this.$toaster.error(res.data.message)
                    }
        })
        .catch(error => {
					this.error_message = '';
					this.errors = new ErrorHandling();
					if (error.response.status == 422) {
						if(error.response.data.status == 'Error'){
                        this.$toaster.error(error.response.data.message)
						}
						else{
							this.errors.record(error.response.data.errors);
						}
					}
				}).finally(() => (this.$parent.loading = false));
        console.log('Chofer registrado');
      
      }
    },
    computed: {},
    components: {
    },
    mounted() {
        var token = localStorage.getItem('token');
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };


    }
  }
  </script>
  