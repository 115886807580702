<template>
    <div>
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card card-custom gutter-b bg-transparent shadow-none border-0">
                                    <div class="card-header align-items-center  border-bottom-dark px-0">
                                        <div class="card-title mb-0">
                                            <h3 class="card-label mb-0 font-weight-bold text-body">
                                            Listado de Guias
                                            </h3>
                                        </div>
                                        <div class="icons d-flex">
                                            <!-- <button class="btn ml-2 p-0 kt_notes_panel_toggle" data-toggle="tooltip" title="" data-placement="right" data-original-title="Check out more demos" v-if="$parent.permissions.includes('account-manage')">
                                                <span class="bg-secondary h-30px font-size-h5 w-30px d-flex align-items-center justify-content-center  rounded-circle shadow-sm " v-on:click="
                                                            display_form = !display_form
                                                        ">
                                                    <svg width="25px" height="25px" viewBox="0 0 16 16" class="bi bi-plus white" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                                                    </svg>
                                                </span>
                                            </button> -->
                                            <!-- <a href="#" onclick="printDiv()" class="ml-2">
                                                <span class="icon h-30px font-size-h5 w-30px d-flex align-items-center justify-content-center rounded-circle ">
                                                    <svg width="15px" height="15px" viewBox="0 0 16 16" class="bi bi-printer-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5z"></path>
                                                        <path fill-rule="evenodd" d="M11 9H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z"></path>
                                                        <path fill-rule="evenodd" d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"></path>
                                                    </svg>
                                                </span>
                                            </a>
                                            <a href="#" class="ml-2">
                                                <span class="icon h-30px font-size-h5 w-30px d-flex align-items-center justify-content-center rounded-circle ">
                                                    <svg width="15px" height="15px" viewBox="0 0 16 16" class="bi bi-file-earmark-text-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M2 2a2 2 0 0 1 2-2h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm7 2l.5-2.5 3 3L10 5a1 1 0 0 1-1-1zM4.5 8a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5z"></path>
                                                    </svg>
                                                </span>
                                            </a> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 ">
                                <div class="card card-custom gutter-b bg-white border-0">
                                    <div class="card-body">
                                        <div>
                                            <div class=" table-responsive" id="printableTable">

                                                <div id="productaccountTable_wrapper" class="dataTables_wrapper no-footer">

                                                <div class="dataTables_length" id="productaccountTable_length"><label>Mostrar
                                                <select name="productaccountTable_length" aria-controls="productaccountTable" class="" v-model="limit" v-on:change="fetchaccounts()">
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="200">200</option>
                                                <option value="500">500</option>
                                                <option value="1000">1000</option>
                                                </select> entradas</label></div>

                                                <div id="productaccountTable_filter" class="dataTables_filter"><label>Buscar:<input type="search" class="" placeholder="" aria-controls="productaccountTable" v-model="searchParameter" @keyup="fetchaccounts()"></label></div>
                                                    <table id="productaccountTable" class="display dataTable no-footer" role="grid">
                                                        <thead class="text-body">
                                                            <tr role="row">
                                                                <th class="sorting" tabindex="0" aria-controls="productaccountTable" rowspan="1" colspan="1" aria-sort="ascending" aria-label="ID: activate to sort column descending"  @click="sorting('id')" :class="(this.$data.sortType == 'asc' || this.$data.sortType == 'ASC') && this.$data.sortBy == 'id'  ? 'sorting_asc' : (this.$data.sortType == 'desc' || this.$data.sortType == 'DESC') && this.$data.sortBy == 'id' ? 'sorting_desc' : 'sorting'">
                                                                    N°
                                                                </th>
                                                                <th class="sorting" tabindex="0" aria-controls="productaccountTable" rowspan="1" colspan="1" aria-label="account: activate to sort column ascending"  @click="sorting('nombre')" :class="(this.$data.sortType == 'asc' || this.$data.sortType == 'ASC') && this.$data.sortBy == 'name'  ? 'sorting_asc' : (this.$data.sortType == 'desc' || this.$data.sortType == 'DESC') && this.$data.sortBy == 'nombre' ? 'sorting_desc' : 'sorting'">
                                                                Remitente
                                                                </th>
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1">
                                                                  Fecha
                                                                </th>
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1">
                                                                Lugar
                                                                </th>
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1">
                                                                   Chofer
                                                                </th>
                                                                 <th class="no-sort sorting_disabled" rowspan="1" colspan="1">
                                                                   Estado
                                                                </th>

                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action" >
                                                                    Accion
                                                                </th>
                                                                 <!-- <th class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action" >
                                                                    Recon. Carga
                                                                </th>
                                                                 <th class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action" >
                                                                    Factura
                                                                </th> -->
                                                            </tr>
                                                        </thead>
                                                        <tbody class="kt-table-tbody text-dark">
                                                            <tr class="kt-table-row kt-table-row-level-0 odd" role="row" v-for="guia in guias" v-bind:key="guia.id">
                                                                <td class="sorting_1">
                                                                    00{{guia.numero}}
                                                                </td>
                                                                <td>
                                                                    {{ guia.remitente }}
                                                                </td>
                                                                <td>
                                                                    {{ guia.fecha }}
                                                                </td>
                                                                <td>{{ guia.lugar }}</td>
                                                                <td>
                                                                    {{ guia.chofer }}
                                                                </td>
                                                                <td>
                                                                    <a href="javascript:void(0)" class="dropdown-item click-edit1" id="click-edit1" data-toggle="tooltip" title="" data-placement="right" data-original-title="Check out more demos" @click="editaccount1(guia); display_form_type = 'type1'"><i class="fa fa-edit"></i></a>
                                                                    {{ guia.caso }}
                                                                </td>

                                                                <td v-if="guia.id > 0">
                                                                    <a href="javascript:void(0)" class="dropdown-item click-edit" id="click-edit2" data-toggle="tooltip" title="" data-placement="right" data-original-title="Check out more demos" @click="editaccount(guia); display_form_type = 'type2'"><i class="fa fa-edit"></i></a>
                                                                    <a class="dropdown-item" href="#" @click="deleteaccount(guia.id)"><i class="fa fa-trash"></i></a>
                                                                    <a class="dropdown-item" href="#" @click="generatepdf(guia.id)"><i class="fa fa-file"></i></a>

                                                                </td>
                                                                <!-- <td>
                                                                    <a class="dropdown-item" href="#" @click="generatereconocimiento(guia.id)"><i class="fa fa-tasks">Imprimir</i></a>
                                                                </td>
                                                                <td>
                                                                    <input type="checkbox" class= "form-control">
                                                                </td> -->
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <ul class="pagination pagination-sm m-0 float-right">
                                                        <li v-bind:class="[{disabled: !pagination.prev_page_url}]"><a class="page-link" href="#" @click="fetchaccounts(pagination.prev_page_url)">Anterior</a></li>

                                                        <li class="disabled"><a class="page-link text-dark" href="#">Pagina {{ pagination.current_page }} de {{ pagination.last_page }}</a></li>

                                                        <li v-bind:class="[{disabled: !pagination.next_page_url}]" class="page-item"><a class="page-link" href="#" @click="fetchaccounts(pagination.next_page_url)">Siguiente</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="offcanvas offcanvas-right kt-color-panel p-8 kt_notes_panel"  v-if="display_form_estado" :class="display_form_estado ? 'offcanvas-on' : ''">
        <div class="offcanvas-header d-flex align-items-center justify-content-between pb-3">
            <h4 class="font-size-h4 font-weight-bold m-0">Cambiar de Estado</h4>
            <a href="#" class="btn btn-sm btn-icon btn-light btn-hover-primary kt_notes_panel_close" v-on:click="clearForm2()">
                <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                </svg>
            </a>
        </div>
        <form id="myform">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-12 col-xl-12">
                            <div class="card card-custom gutter-b bg-white border-0">
                                <div class="card-body">
                                    <form>
                                        <div class="form-group">
                                            <div class="col-12 col-sm-12">
                                                <div class="row">
                                                    <div class="col-6 col-lg-6">
                                                            <label for="destino">Estado Actual:</label>
                                                            <input type="text" class="form-control" id="acuenta" v-model="v_estado" placeholder="Antertior Estado">
                                                        </div>
                                                    <div class="col-6 col-lg-6">
                                                        <label for="origen">Cambiar Estado:</label>
                                                        <input type="checkbox" class="form-control" id="total_pagar" v-model="estado" placeholder="Ingrese el estado">
                                                        <p v-if="estado">Estado cancelado</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" @click="Updatestatus()" class="btn btn-primary">Cambiar Estado</button>
        </form>
    </div>
        <div class="offcanvas offcanvas-right kt-color-panel p-8 kt_notes_panel" v-if="display_form_edit" :class="display_form_edit ? 'offcanvas-on' : ''">
            <div class="offcanvas-header d-flex align-items-center justify-content-between pb-3">
                <h4 class="font-size-h4 font-weight-bold m-0">Guia</h4>
                <a href="#" class="btn btn-sm btn-icon btn-light btn-hover-primary kt_notes_panel_close" v-on:click="clearForm()">
                    <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                    </svg>
                </a>
            </div>
        <form id="myform">
            <div class="row">
                <div class="col-12">

                    <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card card-custom gutter-b bg-white border-0">
                                    <div class="card-body">
                                        <form>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                             <table class="table" style="float: left;">
                                                                <thead>
                                                                    <tr>
                                                                        <th width="5%">Lugar</th>
                                                                        <th width="5%">Fecha</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><input type="text" class="form-control" id="lugar" v-model="guia.lugar" placeholder="Ingrese un lugar "></td>
                                                                        <td><input type="date" class="form-control" id="fecha" v-model="guia.fecha"></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                       </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Remitente:</label>
                                                            <input type="text" class="form-control" id="remitente" v-model="guia.remitente" placeholder="Ingrese nombre remitente">

                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Destino: </label>
                                                            <input type="text" class="form-control" id="destino" v-model="guia.destino" placeholder="ingrese el Destino">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Consignatario:</label>
                                                            <input type="text" class="form-control" id="consignatario" v-model="guia.consignatario" placeholder="Ingrese el consignatario">
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Telefono:</label>
                                                            <input type="text" class="form-control" id="telefono" v-model="guia.telefono" placeholder="ingrese su telefono">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Direccion:</label>
                                                            <input type="text" class="form-control" id="direccion" v-model="guia.direccion" placeholder="Ingrese su direccion">
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Telf:</label>
                                                            <input type="text" class="form-control" id="telefono" v-model="guia.telefono2" placeholder="ingrese su telefono">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- start table  -->
                                            <div class="col-sm-12">
                                                <form @submit.prevent="agregarProducto">
                                                    <label>
                                                    N°Bultos:
                                                    <input type="text" class="form-control" v-model="nbultos">
                                                    </label>
                                                    <label>
                                                    Descripcion:
                                                    <input type="text" class="form-control" v-model="descripcion">
                                                    </label>
                                                    <label>
                                                    Kilos:
                                                    <input type="text" class="form-control" v-model="kilos">
                                                    </label>
                                                    <label>
                                                    Total:
                                                    <input type="text" class="form-control" v-model="total">
                                                    <input type="hidden" class="form-control" v-model="guia_id">
                                                    </label>
                                                    <button type="submit"  class="btn btn-primary">Agregar producto</button>
                                                </form>
                                                <table class="table table-striped table-bordered table-hover" width="100%">
                                                    <thead>
                                                        <tr class="text-center table-primary1">
                                                            <th width="4%">N° BULTOS</th>
                                                            <th width="10%">DESCRICION</th>
                                                            <th width="5%">KILOS</th>
                                                            <th width="5%">TOTAL</th>
                                                            <th width="3%">Accion</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                       <tr v-for="(producto, index) in guia.productos" :key="index">

                                                            <td class="text-center"> <input type="text" class="form-control" v-model="producto.nbultos"></td>
                                                            <td><input type="text" class="form-control" v-model="producto.descripcion"></td>
                                                            <td><input type="text" class="form-control" v-model="producto.kilos"></td>
                                                            <td class="text-center"><input type="text" class="form-control" v-model="producto.total"></td>
                                                            <td>

                                                                <a class="dropdown-item" href="#" @click="eliminarProducto(index, producto.id)"><i class="fa fa-trash"></i></a>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <div class="col-sm-8">
                                                        <div class="form-group row">
                                                            <div class="col-sm-11"  style="border-right: 1px solid white;">
                                                            </div>
                                                            <div class="col-sm-1"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- en table  -->
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Total a Pagar:</label>
                                                            <input type="text" class="form-control" id="total_pagar" v-model="guia.total_pagar" placeholder="Ingrese un monto">
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">A Cuenta:</label>
                                                            <input type="text" class="form-control" id="acuenta" v-model="guia.acuenta" placeholder="ingrese un monto">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                             <div class="col-md-12">
                                                <label class="text-body">Choferes</label>
                                                <fieldset class="form-group mb-3 d-flex">
                                                    <select class="js-example-basic-single js-states form-control bg-transparent" v-model='guia.chofer_id' @change="appendChild($event,'select')">
                                                        <option value="">Selecciona una opcion:</option>
                                                        <option v-for='chofer in guia_dropdowns' :value='chofer.id'
                                                            v-bind:selected="chofer.id"
                                                            v-bind:key="chofer.id"
                                                            v-if="chofer.status == 1"
                                                            >{{ chofer.nombre }}
                                                        </option>
                                                    </select>

                                                </fieldset>
                                                <small class="form-text text-danger" v-if="errors.has('chofer_id')" v-text="errors.get('chofer')"></small>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                </div>
            </div>
            <button type="button" @click="addUpdateaccount()" class="btn btn-primary">Actualizar</button>
        </form>
    </div>

    </div>

    </template>
<script>
import ErrorHandling from "../../ErrorHandling";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from 'html2pdf.js';
import PrintGuia from "./PrintGuia.vue";
import axios from 'axios';
export default {
    components: {
        PrintGuia
    },
    data() {
        return {
            display_form: true,
            display_form_edit: 0,
            display_form_estado: 0,
            display_form_type: '',
            total_pagar: 0,
            guia: {
                numero: '',
                fecha: null,
                lugar: '',
                remitente : '',
                destino: '',
                consignatario: '',
                telefono: '',
                direccion: '',
                telefono2: '',
                total_pagar: '',
                acuenta: '',
                chofer_id : '',
                productos: [],
                test : [],
            },
            nbultos: '',
            descripcion: '',
            kilos: null,
            total: '',
            guia_id: '',
            editando: false,
            v_estado : '',
            estado : false,
            id : null,
            viewToPrint: null,
            searchParameter: '',
            sortBy: 'id',
            sortType: 'ASC',
            limit: 10,
            error_message: '',
            edit: false,
            pagination: {},
            request_method: "",
            accounts: [],
            guias: [],
            guia_dropdowns:[],
            token: [],
            childToAppend:[],
            selected_account_id:"",
            errors: new ErrorHandling(),
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content")
        };
    },


    methods: {
        guiadropdowns(page_url) {
            this.$parent.loading = true;
            page_url = page_url || "/api/guias/create";
            axios.get(page_url, this.token).then(res => {
                this.guia_dropdowns = res.data.data;
                this.guia_id = res.data.numero.id;
                console.log(res.data.data);
            }).finally(() => (this.$parent.loading = false));
        },

        fetchaccounts(page_url) {
            this.$parent.loading = true;
            let vm = this;
            page_url = page_url || "/api/admin/guias/listado";
            let params = {
                limit: this.limit,
                sortBy: this.sortBy,
                sortType: this.sortType,
                search: this.searchParameter,
                startDate: this.startDate,
                endDate: this.endDate,
            };
            axios.get(page_url, {  params: params,
                headers: this.token.headers}).then(res => {
                console.log(res.data.data);
                this.guias = res.data.data.data;
                this.id = res.data.data.data;
                console.log("id de guia", this.id);
                vm.makePagination(res.data.meta, res.data.links);
            }).finally(() => (this.$parent.loading = false));
        },


        makePagination(meta, links) {
            let pagination = {
                current_page: meta.current_page,
                last_page: meta.last_page,
                next_page_url: links.next,
                prev_page_url: links.prev
            };

            this.pagination = pagination;
        },

        eliminarProducto(index, id) {
            this.guia.productos.splice(index, 1);
            if (confirm('Estas Seguro de Eliminar el Registro?')) {
                this.$parent.loading = true;
                axios.delete(`/api/admin/detalleguias/${id}/delete`,this.token)
                    .then(res => {
                    console.log(res);
                        if (res.status == "Success") {
                            this.$toaster.success('Settings has been updated successfully')
                            this.fetchaccounts();
                        }

                    })
                    .catch(err => console.log(err))
                    .finally(() => (this.$parent.loading = false));
            }
        },


        deleteaccount(id) {
            if (confirm('Estas Seguro de Eliminar el Registro?')) {
                this.$parent.loading = true;
                axios.delete(`/api/admin/guias/delete/${id}`,this.token)
                    .then(res => {
                    console.log(res);
                        if (res.data.status == "Success") {
                            this.$toaster.success('Settings has been updated successfully')
                            this.fetchaccounts();
                        }

                    })
                    .catch(err => console.log(err))
                    .finally(() => (this.$parent.loading = false));
            }
        },
        // funcion para la impresion de pdf de un guia
        generatepdf(id){
            if (confirm('Solo podras imprimir una vez.')) {
                this.$parent.loading = true;
                axios.post(`/api/guias/pdf`,{
                id: id
                }, {responseType: 'arraybuffer'})
                    .then(response => {
                        const blob = new Blob([response.data], { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(blob);
                        window.open(url, '_blank');

                    })
                    .catch(err => console.log(err))
                    .finally(() => (this.$parent.loading = false));

            }
        },
        // fin de la funcion para la impresion de guia

        // funcion para la impresion de pdf de reconocimiento de carga
        generatereconocimiento(id){
            this.$parent.loading = true;
                axios.post(`/api/reconocimiento/pdf`,{
                id: id
                }, {responseType: 'arraybuffer'})
                    .then(response => {
                        const blob = new Blob([response.data], { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(blob);
                        window.open(url, '_blank');

                    })
                    .catch(err => console.log(err))
                    .finally(() => (this.$parent.loading = false));

        },
        // fin de la funcion para la impresion de reconocimiento de carga
        // guardar o actualizar
        addUpdateaccount() {
            this.$parent.loading = true;
            var url = '/api/admin/guias/listado';
            if (this.edit === false) {
                // Add
                this.request_method = 'post'
            } else {
                // Update
                var url = '/api/admin/guias/'+ this.guia.id +'/update' ;
                this.request_method = 'put'
            }

            axios[this.request_method](url,
            {
                fecha:this.guia.fecha,
                lugar:this.guia.lugar,
                remitente:this.guia.remitente,
                destino:this.guia.destino,
                consignatario:this.guia.consignatario,
                telefono:this.guia.telefono,
                direccion:this.guia.direccion,
                telefono2:this.guia.telefono2,
                total_pagar:this.guia.total_pagar,
                acuenta:this.guia.acuenta,
                chofer_id:this.guia.chofer_id,
                productos:this.guia.productos,

            },
                this.token)
                .then(res => {
                    if (res.data.status == "Success") {
                        this.display_form = 0;
                        this.$toaster.success('Settings has been updated successfully')
                        this.clearForm();
                        this.fetchaccounts();
                    } else {
                        this.$toaster.error(res.data.message)
                    }
                })
                .catch(error => {
					this.error_message = '';
					this.errors = new ErrorHandling();
					if (error.response.status = 422) {
						if(error.response.data.status == 'Error'){
							this.error_message = error.response.data.message;
						}
						else{
							this.errors.record(error.response.data.errors);
						}
					}
				}).finally(() => (this.$parent.loading = false));

        },
        // fin proceso de registro
        Updatestatus(){
            this.$parent.loading = true;
            var url = '/api/admin/guias/listado';
            if (this.edit === false) {
                // Add
                this.request_method = 'post'
            } else {
                // Update
                var url = '/api/admin/guias/'+ this.id +'/actualizaestado' ;
                this.request_method = 'put'
            }
            axios[this.request_method](url,
            {
                caso: this.estado ? 'cancelado' : 'pendiente',

            },
            this.token)
                .then(res => {
                    if (res.data.status == "Success") {
                        this.display_form = 0;
                        this.$toaster.success('Settings has been updated successfully')
                        this.clearForm();
                        this.fetchaccounts();
                    } else {
                        this.$toaster.error(res.data.message)
                    }
                })
                .catch(error => {
					this.error_message = '';
					this.errors = new ErrorHandling();
					if (error.response.status = 422) {
						if(error.response.data.status == 'Error'){
							this.error_message = error.response.data.message;
						}
						else{
							this.errors.record(error.response.data.errors);
						}
					}
				}).finally(() => (this.$parent.loading = false));

        },

        editaccount1(guia) {
            this.edit = true;
            this.display_form_estado = 1;
            this.errors = new ErrorHandling();
            this.id = guia.id;
            this.v_estado = guia.caso;
        },
        clearForm2() {
            this.display_form_estado = 0;
            this.edit = false;
            this.estado = "";
        },

        editaccount(guia) {
            this.edit = true;
            this.display_form_edit = 1;
            this.errors = new ErrorHandling();
            this.guia.id = guia.id;
            this.guia.fecha = guia.fecha;
            this.guia.lugar = guia.lugar;
            this.guia.remitente = guia.remitente;
            this.guia.destino = guia.destino;
            this.guia.consignatario = guia.consignatario;
            this.guia.telefono = guia.telefono;
            this.guia.direccion = guia.direccion;
            this.guia.telefono2 = guia.telefono2;
            this.guia.total_pagar = guia.total_pagar;
            this.guia.acuenta = guia.acuenta;
            this.guia.chofer_id = guia.chofer_id;
            this.guia.test = guia.productos;


            this.$parent.loading = true;

            axios.get( `/api/admin/guias/${guia.id}`, this.token).then(res => {
                this.guia.productos = res.data.data;
                console.log(res.data.data);
            }).finally(() => (this.$parent.loading = false));
            console.log(this.guia.acuenta);

        },
        clearForm() {
            this.display_form_edit = 0;
            this.edit = false;
            this.guia.id = null;
            this.guia.fecha = "";
            this.guia.lugar = "";
            this.guia.remitente = "";
            this.guia.destino = "";
            this.guia.consignatario = "";
            this.guia.telefono = "";
            this.guia.direccion = "";
            this.guia.telefono2 = "";
            this.guia.total_pagar = "";
            this.guia.acuenta = "";
            this.guia.chofer_id = "";
        },

        sorting(sortBy){
            this.sortBy = sortBy;
            this.sortType = this.sortType == 'asc' || this.sortType == 'ASC' ? this.sortType='desc' : this.sortType = 'asc';
            this.fetchaccounts();
        },
         appendChild(e,select){
            this.findChild(e.target.value,select);
        },
        findChild(parent,select){
           for(var i = 0; i < this.childToAppend.length;i++){
                if(this.childToAppend[i].select == select){
                    this.childToAppend.splice(i);
                }
            }

            axios.get("/api/admin/account?parent_id="+parent, this.token).then(res => {
               this.selected_account_id = parent;
                if(res.data.data.length > 0){
                    var data = res.data.data;
                    this.childToAppend.push({data,select});
                }

            });

        },
        agregarProducto(){
                this.guia.productos.push({ nbultos: this.nbultos, descripcion: this.descripcion, kilos: this.kilos, total: this.total, guia_id: this.guia_id, editando: false });
                this.nbultos = '';
                this.descripcion = '';
                this.kilos = '';
                this.total = '';
            }


    },
    mounted() {

        var token = localStorage.getItem('token');
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        this.fetchaccounts();
        this.guiadropdowns();
    }
};
</script>
