var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 " }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0"
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: " table-responsive",
                            attrs: { id: "printableTable" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "dataTables_wrapper no-footer",
                                attrs: { id: "productaccountTable_wrapper" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "dataTables_length",
                                    attrs: { id: "productaccountTable_length" }
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Mostrar\n                                            "
                                      ),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.limit,
                                              expression: "limit"
                                            }
                                          ],
                                          attrs: {
                                            name: "productaccountTable_length",
                                            "aria-controls":
                                              "productaccountTable"
                                          },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.limit = $event.target
                                                  .multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              },
                                              function($event) {
                                                return _vm.fetchaccounts()
                                              }
                                            ]
                                          }
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "10" } },
                                            [_vm._v("10")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "25" } },
                                            [_vm._v("25")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "50" } },
                                            [_vm._v("50")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "100" } },
                                            [_vm._v("100")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "200" } },
                                            [_vm._v("200")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "500" } },
                                            [_vm._v("500")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "1000" } },
                                            [_vm._v("1000")]
                                          )
                                        ]
                                      ),
                                      _vm._v(" Entradas")
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dataTables_filter",
                                    attrs: { id: "productaccountTable_filter" }
                                  },
                                  [
                                    _c("label", [
                                      _vm._v("Buscar:"),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.searchParameter,
                                            expression: "searchParameter"
                                          }
                                        ],
                                        attrs: {
                                          type: "search",
                                          placeholder: "",
                                          "aria-controls": "productaccountTable"
                                        },
                                        domProps: {
                                          value: _vm.searchParameter
                                        },
                                        on: {
                                          keyup: function($event) {
                                            return _vm.fetchaccounts()
                                          },
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.searchParameter =
                                              $event.target.value
                                          }
                                        }
                                      })
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  {
                                    staticClass: "display dataTable no-footer",
                                    attrs: {
                                      id: "productaccountTable",
                                      role: "grid"
                                    }
                                  },
                                  [
                                    _c("thead", { staticClass: "text-body" }, [
                                      _c("tr", { attrs: { role: "row" } }, [
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy == "id"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy == "id"
                                                ? "sorting_desc"
                                                : "sorting",
                                            attrs: {
                                              tabindex: "0",
                                              "aria-controls":
                                                "productaccountTable",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-sort": "ascending",
                                              "aria-label":
                                                "ID: activate to sort column descending"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.sorting("id")
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                                N°\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy == "name"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy == "nombre"
                                                ? "sorting_desc"
                                                : "sorting",
                                            attrs: {
                                              tabindex: "0",
                                              "aria-controls":
                                                "productaccountTable",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label":
                                                "account: activate to sort column ascending"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.sorting("nombre")
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                            Fecha\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                              Destino\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                            Pago Origen\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                              Pago Destino\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                                Total\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                                A Cuenta\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                                Chofer\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                                Accion\n                                                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            attrs: {
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label": "Action"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                                Estado\n                                                            "
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      {
                                        staticClass: "kt-table-tbody text-dark"
                                      },
                                      _vm._l(_vm.reconocimientos, function(
                                        reconocimiento
                                      ) {
                                        return _c(
                                          "tr",
                                          {
                                            key: reconocimiento.id,
                                            staticClass:
                                              "kt-table-row kt-table-row-level-0 odd",
                                            attrs: { role: "row" }
                                          },
                                          [
                                            _c(
                                              "td",
                                              { staticClass: "sorting_1" },
                                              [
                                                _vm._v(
                                                  "\n                                                                00" +
                                                    _vm._s(reconocimiento.id) +
                                                    "\n                                                            "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(reconocimiento.fecha) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(
                                                    reconocimiento.destino
                                                  ) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  reconocimiento.pago_origen
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(
                                                    reconocimiento.pago_destino
                                                  ) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(reconocimiento.total) +
                                                  "\n                                                             "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(
                                                    reconocimiento.acuenta
                                                  ) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                                                " +
                                                  _vm._s(
                                                    reconocimiento.chofer.nombre
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    reconocimiento.chofer
                                                      .apellido
                                                  ) +
                                                  "\n                                                            "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            reconocimiento.id > 0
                                              ? _c("td", [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "dropdown-item click-edit1",
                                                      attrs: {
                                                        href:
                                                          "javascript:void(0)",
                                                        id: "click-edit1",
                                                        "data-toggle":
                                                          "tooltip",
                                                        title: "",
                                                        "data-placement":
                                                          "right",
                                                        "data-original-title":
                                                          "Check out more demos"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.editaccount(
                                                            reconocimiento
                                                          )
                                                          _vm.display_form_type =
                                                            "type1"
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-edit"
                                                      })
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "dropdown-item",
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deleteaccount(
                                                            reconocimiento.id
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-trash"
                                                      })
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "dropdown-item",
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.generatepdf(
                                                            reconocimiento.id
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-file"
                                                      })
                                                    ]
                                                  )
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "dropdown-item click-edit2",
                                                  attrs: {
                                                    href: "javascript:void(0)",
                                                    id: "click-edit2",
                                                    "data-toggle": "tooltip",
                                                    title: "",
                                                    "data-placement": "right",
                                                    "data-original-title":
                                                      "Check out more demos"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.editestado(
                                                        reconocimiento
                                                      )
                                                      _vm.display_form_type =
                                                        "type2"
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-edit"
                                                  })
                                                ]
                                              )
                                            ])
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "pagination pagination-sm m-0 float-right"
                                  },
                                  [
                                    _c(
                                      "li",
                                      {
                                        class: [
                                          {
                                            disabled: !_vm.pagination
                                              .prev_page_url
                                          }
                                        ]
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "page-link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                return _vm.fetchaccounts(
                                                  _vm.pagination.prev_page_url
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Previous")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "disabled" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "page-link text-dark",
                                          attrs: { href: "#" }
                                        },
                                        [
                                          _vm._v(
                                            "Page " +
                                              _vm._s(
                                                _vm.pagination.current_page
                                              ) +
                                              " of " +
                                              _vm._s(_vm.pagination.last_page)
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "page-item",
                                        class: [
                                          {
                                            disabled: !_vm.pagination
                                              .next_page_url
                                          }
                                        ]
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "page-link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                return _vm.fetchaccounts(
                                                  _vm.pagination.next_page_url
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Next")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.display_form_type === "type1"
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.display_form,
                expression: "display_form"
              }
            ],
            staticClass:
              "offcanvas offcanvas-right kt-color-panel p-8 kt_notes_panel",
            class: _vm.display_form ? "offcanvas-on" : "",
            attrs: { id: "click-edit1" }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "offcanvas-header d-flex align-items-center justify-content-between pb-3"
              },
              [
                _c("h4", { staticClass: "font-size-h4 font-weight-bold m-0" }, [
                  _vm._v("Editar Conocimiento de Carga")
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "btn btn-sm btn-icon btn-light btn-hover-primary kt_notes_panel_close",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        _vm.clearForm()
                        _vm.display_form = false
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "bi bi-x",
                        attrs: {
                          width: "20px",
                          height: "20px",
                          viewBox: "0 0 16 16",
                          fill: "currentColor",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            d:
                              "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "form",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.display_form,
                    expression: "display_form"
                  }
                ],
                attrs: { id: "myform" }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card card-custom gutter-b bg-white border-0"
                          },
                          [
                            _c("div", { staticClass: "card-body" }, [
                              _c("form", [
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-12" },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-6 col-lg-6" },
                                          [
                                            _c(
                                              "table",
                                              {
                                                staticClass: "table",
                                                staticStyle: { float: "left" }
                                              },
                                              [
                                                _vm._m(1),
                                                _vm._v(" "),
                                                _c("tbody", [
                                                  _c("tr", [
                                                    _c("td", [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.reconocimiento
                                                                .fecha,
                                                            expression:
                                                              "reconocimiento.fecha"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          type: "date",
                                                          id: "fecha"
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.reconocimiento
                                                              .fecha
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              _vm.reconocimiento,
                                                              "fecha",
                                                              $event.target
                                                                .value
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ])
                                                  ])
                                                ])
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-12" },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-6 col-lg-6" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-md-12" },
                                              [
                                                _c(
                                                  "label",
                                                  { staticClass: "text-body" },
                                                  [_vm._v("Choferes")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "fieldset",
                                                  {
                                                    staticClass:
                                                      "form-group mb-3 d-flex"
                                                  },
                                                  [
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.reconocimiento
                                                                .chofer_id,
                                                            expression:
                                                              "reconocimiento.chofer_id"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "js-example-basic-single js-states form-control bg-transparent",
                                                        on: {
                                                          change: [
                                                            function($event) {
                                                              var $$selectedVal = Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function(o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function(
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                              _vm.$set(
                                                                _vm.reconocimiento,
                                                                "chofer_id",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                            function($event) {
                                                              return _vm.appendChild(
                                                                $event,
                                                                "select"
                                                              )
                                                            }
                                                          ]
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: { value: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Selecciona una opcion:"
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          _vm.guia_dropdowns,
                                                          function(chofer) {
                                                            return chofer.status ==
                                                              1
                                                              ? _c(
                                                                  "option",
                                                                  {
                                                                    key:
                                                                      chofer.id,
                                                                    domProps: {
                                                                      value:
                                                                        chofer.id,
                                                                      selected:
                                                                        chofer.id
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        chofer.nombre
                                                                      ) +
                                                                        "\n                                                                        "
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.errors.has("chofer_id")
                                                  ? _c("small", {
                                                      staticClass:
                                                        "form-text text-danger",
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          _vm.errors.get(
                                                            "chofer"
                                                          )
                                                        )
                                                      }
                                                    })
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-6 col-lg-6" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "destino" } },
                                              [_vm._v("Destino: ")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.reconocimiento.destino,
                                                  expression:
                                                    "reconocimiento.destino"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "destino",
                                                placeholder:
                                                  "ingrese el Destino"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.reconocimiento.destino
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.reconocimiento,
                                                    "destino",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-sm-12" }, [
                                  _c(
                                    "form",
                                    {
                                      on: {
                                        submit: function($event) {
                                          $event.preventDefault()
                                          return _vm.agregarDetalle.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "text-body" },
                                        [_vm._v("Guias")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "fieldset",
                                        {
                                          staticClass: "form-group mb-3 d-flex"
                                        },
                                        [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.nguia,
                                                  expression: "nguia"
                                                }
                                              ],
                                              staticClass:
                                                "js-example-basic-single js-states form-control bg-transparent",
                                              attrs: { id: "my-select" },
                                              on: {
                                                change: [
                                                  function($event) {
                                                    var $$selectedVal = Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function(o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function(o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                    _vm.nguia = $event.target
                                                      .multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  },
                                                  function($event) {
                                                    return _vm.appendChild(
                                                      $event,
                                                      "select"
                                                    )
                                                  }
                                                ]
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                { attrs: { value: "" } },
                                                [
                                                  _vm._v(
                                                    "Selecciona una opcion:"
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(_vm.listadoguias, function(
                                                listguia
                                              ) {
                                                return listguia.status == 1
                                                  ? _c(
                                                      "option",
                                                      {
                                                        key: listguia.id,
                                                        domProps: {
                                                          value: listguia.id,
                                                          selected: listguia.id
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "00" +
                                                            _vm._s(
                                                              listguia.numero
                                                            ) +
                                                            "\n                                                                "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              })
                                            ],
                                            2
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          attrs: { type: "submit" }
                                        },
                                        [_vm._v("Agregar producto")]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "table table-striped table-bordered table-hover",
                                      attrs: { width: "100%" }
                                    },
                                    [
                                      _vm._m(2),
                                      _vm._v(" "),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.reconocimiento.productos,
                                          function(producto, index) {
                                            return _c("tr", { key: index }, [
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          producto.consignatario,
                                                        expression:
                                                          "producto.consignatario"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      value:
                                                        producto.consignatario
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          producto,
                                                          "consignatario",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: producto.guia,
                                                      expression:
                                                        "producto.guia"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    value: producto.guia
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        producto,
                                                        "guia",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: producto.bultos,
                                                      expression:
                                                        "producto.bultos"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    value: producto.bultos
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        producto,
                                                        "bultos",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          producto.contenido,
                                                        expression:
                                                          "producto.contenido"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      value: producto.contenido
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          producto,
                                                          "contenido",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: producto.importe,
                                                        expression:
                                                          "producto.importe"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      value: producto.importe
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          producto,
                                                          "importe",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "dropdown-item",
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.eliminarProducto(
                                                          index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "fa fa-trash"
                                                    })
                                                  ]
                                                )
                                              ])
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _vm._m(3),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-12" },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-6 col-lg-6" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "origen" } },
                                              [_vm._v("Pago en Destino:")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.reconocimiento
                                                      .pago_destino,
                                                  expression:
                                                    "reconocimiento.pago_destino"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "total_pagar",
                                                placeholder: "Ingrese un monto"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.reconocimiento
                                                    .pago_destino
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.reconocimiento,
                                                    "pago_destino",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-6 col-lg-6" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "destino" } },
                                              [_vm._v("Pago en Origen:")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.reconocimiento
                                                      .pago_origen,
                                                  expression:
                                                    "reconocimiento.pago_origen"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "acuenta",
                                                placeholder: "ingrese un monto"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.reconocimiento.pago_origen
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.reconocimiento,
                                                    "pago_origen",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-12" },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-6 col-lg-6" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "origen" } },
                                              [_vm._v("Total Flete:")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.reconocimiento.total,
                                                  expression:
                                                    "reconocimiento.total"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "total_pagar",
                                                placeholder: "Ingrese un monto"
                                              },
                                              domProps: {
                                                value: _vm.reconocimiento.total
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.reconocimiento,
                                                    "total",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-6 col-lg-6" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "destino" } },
                                              [_vm._v("A cuenta:")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.reconocimiento.acuenta,
                                                  expression:
                                                    "reconocimiento.acuenta"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "acuenta",
                                                placeholder: "ingrese un monto"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.reconocimiento.acuenta
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.reconocimiento,
                                                    "acuenta",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-sm-12" },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-6 col-lg-6" },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "origen" } },
                                              [_vm._v("Dias Entrega:")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.reconocimiento.dias,
                                                  expression:
                                                    "reconocimiento.dias"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "total_pagar",
                                                placeholder:
                                                  "Ingrese cantidad de dias a entregar"
                                              },
                                              domProps: {
                                                value: _vm.reconocimiento.dias
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.reconocimiento,
                                                    "dias",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              ])
                            ])
                          ]
                        )
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.addUpdateaccount()
                      }
                    }
                  },
                  [_vm._v("Guardar")]
                )
              ]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.display_form_estado
      ? _c(
          "div",
          {
            staticClass:
              "offcanvas offcanvas-right kt-color-panel p-8 kt_notes_panel",
            class: _vm.display_form_estado ? "offcanvas-on" : ""
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "offcanvas-header d-flex align-items-center justify-content-between pb-3"
              },
              [
                _c("h4", { staticClass: "font-size-h4 font-weight-bold m-0" }, [
                  _vm._v("Estado de Conocimiento de Carga")
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "btn btn-sm btn-icon btn-light btn-hover-primary kt_notes_panel_close",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        return _vm.clearCanvas()
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "bi bi-x",
                        attrs: {
                          width: "20px",
                          height: "20px",
                          viewBox: "0 0 16 16",
                          fill: "currentColor",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            d:
                              "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("form", { attrs: { id: "myform" } }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card card-custom gutter-b bg-white border-0"
                        },
                        [
                          _c("div", { staticClass: "card-body" }, [
                            _c("form", [
                              _c("div", { staticClass: "form-group" }, [
                                _c("div", { staticClass: "col-12 col-sm-12" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-6 col-lg-6" },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "destino" } },
                                          [_vm._v("Total Anterior:")]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.a_cuenta,
                                              expression: "a_cuenta"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            id: "acuenta",
                                            placeholder: "ingrese un monto"
                                          },
                                          domProps: { value: _vm.a_cuenta },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.a_cuenta = $event.target.value
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-6 col-lg-6" },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "origen" } },
                                          [_vm._v("Pago Actual:")]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.pagoactual,
                                              expression: "pagoactual"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "checkboox",
                                            id: "total_pagar"
                                          },
                                          domProps: { value: _vm.pagoactual },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.pagoactual =
                                                $event.target.value
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-12 col-sm-12" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-6 col-lg-6" },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "origen" } },
                                          [_vm._v("Estado:")]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.estado,
                                              expression: "estado"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "checkbox",
                                            id: "total_pagar",
                                            placeholder: "Ingrese un monto"
                                          },
                                          domProps: {
                                            checked: Array.isArray(_vm.estado)
                                              ? _vm._i(_vm.estado, null) > -1
                                              : _vm.estado
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a = _vm.estado,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    (_vm.estado = $$a.concat([
                                                      $$v
                                                    ]))
                                                } else {
                                                  $$i > -1 &&
                                                    (_vm.estado = $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      ))
                                                }
                                              } else {
                                                _vm.estado = $$c
                                              }
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _vm.estado
                                          ? _c("p", [_vm._v("Estado pagado")])
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                ])
                              ])
                            ])
                          ])
                        ]
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.Updatestatus()
                    }
                  }
                },
                [_vm._v("Gaurdar")]
              )
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "card card-custom gutter-b bg-transparent shadow-none border-0"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-header align-items-center  border-bottom-dark px-0"
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body"
                    },
                    [
                      _vm._v(
                        "\n                                        Listado de Conocimiento de Carga\n                                        "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "icons d-flex" })
              ]
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [_c("th", { attrs: { width: "5%" } }, [_vm._v("Fecha")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "text-center table-primary1" }, [
        _c("th", { attrs: { width: "6%" } }, [_vm._v("Consignatario")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "5%" } }, [_vm._v("Guia N°")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "5%" } }, [_vm._v("N° Bultos")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "10%" } }, [_vm._v("Contenido")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "3%" } }, [_vm._v("Importe")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "3%" } }, [_vm._v("Accion")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-8" }, [
          _c("div", { staticClass: "form-group row" }, [
            _c("div", {
              staticClass: "col-sm-11",
              staticStyle: { "border-right": "1px solid white" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-1" })
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }