var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.$parent.permissions.includes("dashboard")
      ? _c(
          "div",
          {
            staticClass: "row",
            staticStyle: {
              "justify-content": "center",
              "align-items": "center !important"
            }
          },
          [_vm._m(0)]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.$parent.permissions.includes("dashboard")
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-6 col-xl-3" }, [
            _c(
              "div",
              {
                staticClass:
                  "\n          card card-custom\n          gutter-b\n          bg-white\n          border-0\n          theme-circle theme-circle-primary\n        "
              },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c("h3", { staticClass: "text-body font-weight-bold" }, [
                    _vm._v("Reconocimiento de Carga")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-3" }, [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-dark font-weight-bold font-size-h1 mr-3"
                        },
                        [_vm._v(_vm._s(_vm.totalreconocimiento))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold font-size-h0",
                          class:
                            _vm.totalreconocimiento < 0
                              ? "text-danger"
                              : "text-success"
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.totalreconocimiento) +
                              "\n              "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "svg-icon",
                          class:
                            _vm.totalreconocimiento < 0
                              ? "text-danger"
                              : "text-success"
                        },
                        [
                          _c("i", {
                            staticClass: "fas",
                            class:
                              _vm.totalreconocimiento < 0
                                ? "fa-arrow-down"
                                : "fa-arrow-up"
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-black-50 mt-3" })
                  ])
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-6 col-xl-3" }, [
            _c(
              "div",
              {
                staticClass:
                  "\n          card card-custom\n          gutter-b\n          bg-white\n          border-0\n          theme-circle theme-circle-success\n        "
              },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c("h3", { staticClass: "text-body font-weight-bold" }, [
                    _vm._v("Choferes")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-3" }, [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-dark font-weight-bold font-size-h1 mr-3"
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.totalchofer) +
                              " \n              "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold font-size-h0",
                          class:
                            _vm.totalchofer < 0 ? "text-danger" : "text-success"
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.totalchofer) +
                              "\n              "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "svg-icon",
                          class:
                            _vm.totalchofer < 0 ? "text-danger" : "text-success"
                        },
                        [
                          _c("i", {
                            staticClass: "fas",
                            class:
                              _vm.totalchofer < 0
                                ? "fa-arrow-down"
                                : "fa-arrow-up"
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-black-50 mt-3" })
                  ])
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-6 col-xl-3" }, [
            _c(
              "div",
              {
                staticClass:
                  "\n          card card-custom\n          gutter-b\n          bg-white\n          border-0\n          theme-circle theme-circle-info\n        "
              },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c("h3", { staticClass: "text-body font-weight-bold" }, [
                    _vm._v("Guias")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-3" }, [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-dark font-weight-bold font-size-h1 mr-3"
                        },
                        [_vm._v(_vm._s(_vm.totalguias))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold font-size-h0",
                          class:
                            _vm.totalguias < 0 ? "text-danger" : "text-success"
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.totalguias) +
                              "\n              "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "svg-icon",
                          class:
                            _vm.totalguias < 0 ? "text-danger" : "text-success"
                        },
                        [
                          _c("i", {
                            staticClass: "fas",
                            class:
                              _vm.totalguias < 0
                                ? "fa-arrow-down"
                                : "fa-arrow-up"
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-black-50 mt-3" })
                  ])
                ])
              ]
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.$parent.permissions.includes("dashboard")
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-6 col-xl-8" }, [
            _c(
              "div",
              { staticClass: "card card-custom gutter-b bg-white border-0" },
              [
                _c(
                  "div",
                  { staticClass: "card-header align-items-center border-0" },
                  [
                    _c("div", { staticClass: "card-title mb-0" }, [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "card-label text-body font-weight-bold mb-0"
                        },
                        [_vm._v("Usuarios")]
                      ),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.totalusuarios) +
                          "\n          "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "card-body pt-3" }, [
                  _c(
                    "div",
                    { attrs: { id: "chart" } },
                    [
                      _c("apexchart", {
                        attrs: {
                          type: "line",
                          height: "350",
                          options: _vm.chartOptions,
                          series: _vm.series
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-6 col-xl-4" }, [
            _c(
              "div",
              { staticClass: "card card-custom gutter-b bg-white border-0" },
              [
                _vm._m(1),
                _vm._v(" "),
                _c("div", { staticClass: "card-body px-0" }, [
                  _c(
                    "ul",
                    {
                      staticClass: "list-group scrollbar-1",
                      staticStyle: { height: "300px" }
                    },
                    [
                      _c(
                        "li",
                        {
                          staticClass:
                            "\n                list-group-item list-group-item-action\n                border-0\n                d-flex\n                align-items-center\n                justify-content-between\n                py-2\n              "
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "list-left d-flex align-items-center"
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "\n                    d-flex\n                    align-items-center\n                    justify-content-center\n                    rounded\n                    svg-icon\n                    w-45px\n                    h-45px\n                    bg-primary\n                    text-white\n                    mr-2\n                  "
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-lightning-fill",
                                      attrs: {
                                        width: "20px",
                                        height: "20px",
                                        viewBox: "0 0 16 16",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "list-content" }, [
                                _c(
                                  "span",
                                  { staticClass: "list-title text-body" },
                                  [_vm._v("Total Guias")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  { staticClass: "text-muted d-block" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.guiasLastMonthCount) +
                                        " Nuevas Guias"
                                    )
                                  ]
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.totalguias))])
                        ]
                      ),
                      _vm._v(" "),
                      _c("li", {
                        staticClass:
                          "\n                list-group-item list-group-item-action\n                border-0\n                d-flex\n                align-items-center\n                justify-content-between\n                py-2\n              "
                      }),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          staticClass:
                            "\n                list-group-item list-group-item-action\n                border-0\n                d-flex\n                align-items-center\n                justify-content-between\n                py-2\n              "
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "list-left d-flex align-items-center"
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "\n                    d-flex\n                    align-items-center\n                    justify-content-center\n                    rounded\n                    svg-icon\n                    w-45px\n                    h-45px\n                    bg-secondary\n                    text-white\n                    mr-2\n                  "
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-bar-chart-line-fill",
                                      attrs: {
                                        width: "20px",
                                        height: "20px",
                                        viewBox: "0 0 16 16",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2z"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "list-content" }, [
                                _c(
                                  "span",
                                  { staticClass: "list-title text-body" },
                                  [_vm._v("Total reconocimiento de carga")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  { staticClass: "text-muted d-block" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.cargaLastMonthCount) +
                                        " Nueva Carga"
                                    )
                                  ]
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.totalreconocimiento))])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          staticClass:
                            "\n                list-group-item list-group-item-action\n                border-0\n                d-flex\n                align-items-center\n                justify-content-between\n                py-2\n              "
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "list-left d-flex align-items-center"
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "\n                    d-flex\n                    align-items-center\n                    justify-content-center\n                    rounded\n                    svg-icon\n                    w-45px\n                    h-45px\n                    bg-warning\n                    text-white\n                    mr-2\n                  "
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-lightning-fill",
                                      attrs: {
                                        width: "20px",
                                        height: "20px",
                                        viewBox: "0 0 16 16",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "list-content" }, [
                                _c(
                                  "span",
                                  { staticClass: "list-title text-body" },
                                  [_vm._v("Total Usuarios")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  { staticClass: "text-muted d-block" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.thisWeekCustomer) +
                                        "Nuevo Usuario"
                                    )
                                  ]
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.totalCustomers))])
                        ]
                      )
                    ]
                  )
                ])
              ]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "notfound", staticStyle: { "margin-top": "200px" } },
      [_c("p", [_vm._v("You Don't Have Permission To View Dashboard")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header align-items-center border-0" },
      [
        _c("div", { staticClass: "card-title mb-0" }, [
          _c(
            "h3",
            { staticClass: "card-label text-body font-weight-bold mb-0" },
            [_vm._v("\n              Ultima Actualizacion\n            ")]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }