var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header header-fixed", attrs: { id: "tc_header" } },
    [
      _c(
        "div",
        {
          staticClass:
            "container-fluid d-flex align-items-stretch justify-content-between"
        },
        [
          _c(
            "div",
            {
              staticClass: "header-menu-wrapper header-menu-wrapper-left",
              attrs: { id: "tc_header_menu_wrapper" }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "header-menu header-menu-mobile header-menu-layout-default",
                  attrs: { id: "tc_header_menu" }
                },
                [
                  _c("ul", { staticClass: "menu-nav" }, [
                    _c(
                      "li",
                      {
                        staticClass:
                          "menu-item menu-item-open menu-item-here menu-item-submenu menu-item-rel menu-item-open menu-item-here menu-item-active p-0",
                        attrs: {
                          "data-menu-toggle": "click",
                          "aria-haspopup": "true"
                        },
                        on: {
                          click: function($event) {
                            return _vm.setBurgerMenu()
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "btn  btn-clean btn-dropdown mr-0 p-0",
                            attrs: { id: "tc_aside_toggle" }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "svg-icon svg-icon-xl svg-icon-primary"
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "bi bi-list",
                                    attrs: {
                                      width: "24px",
                                      height: "24px",
                                      viewBox: "0 0 16 16",
                                      fill: "currentColor",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        "fill-rule": "evenodd",
                                        d:
                                          "M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "topbar" }, [
            _c(
              "div",
              {
                staticClass: "dropdown",
                class: { show: _vm.showNotifications }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "topbar-item",
                    attrs: {
                      "data-toggle": "dropdown",
                      "data-display": "static",
                      "aria-expanded": _vm.showNotifications
                    },
                    on: { click: _vm.toggleNotifications }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "btn btn-icon w-auto btn-clean d-flex align-items-center pr-1 pl-3"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "svg-icon svg-icon-xl svg-icon-primary"
                          },
                          [
                            _c(
                              "svg",
                              {
                                staticClass: "bi bi-bell",
                                attrs: {
                                  width: "20px",
                                  height: "20px",
                                  viewBox: "0 0 16 16",
                                  fill: "currentColor",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M8 16a2 2 0 0 0 1.985-1.75H6.015A2 2 0 0 0 8 16zm.35-14.444a1 1 0 0 0-1.8 0 5.987 5.987 0 0 0-2.826 5.13c-.032.276-.138.538-.263.804-.16.337-.337.676-.337 1.268v.535l-1.186.914A1 1 0 0 0 3.537 13h8.926a1 1 0 0 0 .759-1.663l-1.186-.914v-.535c0-.592-.177-.93-.337-1.268a4.12 4.12 0 0 1-.263-.804A5.987 5.987 0 0 0 8.35 1.556z"
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _vm.unreadNotifications.length > 0
                              ? _c(
                                  "span",
                                  { staticClass: "badge badge-danger" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.unreadNotifications.length)
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dropdown-menu dropdown-menu-right",
                    class: { show: _vm.showNotifications },
                    staticStyle: { "min-width": "250px" }
                  },
                  [
                    _vm.unreadNotifications.length === 0
                      ? _c(
                          "div",
                          { staticClass: "dropdown-item text-center" },
                          [_vm._v("No hay notificaciones nuevas")]
                        )
                      : _c(
                          "div",
                          [
                            _c("h6", { staticClass: "dropdown-header" }, [
                              _vm._v("Nuevas Notificaciones")
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.unreadNotifications, function(
                              notification,
                              index
                            ) {
                              return _c(
                                "a",
                                {
                                  key: index,
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      return _vm.markAsRead(
                                        notification.id,
                                        index
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-items-start" },
                                    [
                                      _c("img", {
                                        staticClass: "rounded-circle mr-3",
                                        staticStyle: {
                                          width: "40px",
                                          height: "40px"
                                        },
                                        attrs: {
                                          src: notification.user.avatar
                                            ? notification.user.avatar
                                            : "/images/jugador.png",
                                          alt: "user-image"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "mb-0 font-weight-bold"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(notification.user.name) +
                                                " " +
                                                _vm._s(
                                                  notification.user.first_name
                                                )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "small",
                                          { staticClass: "text-muted" },
                                          [_vm._v(_vm._s(notification.message))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "small",
                                          { staticClass: "text-muted d-block" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatDate(
                                                  notification.created_at
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        ),
                    _vm._v(" "),
                    _vm.readNotifications.length > 0
                      ? _c(
                          "div",
                          [
                            _c("h6", { staticClass: "dropdown-header" }, [
                              _vm._v("Notificaciones Leídas")
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.readNotifications, function(
                              notification,
                              index
                            ) {
                              return _c(
                                "a",
                                {
                                  key: "read-" + index,
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-items-start" },
                                    [
                                      _c("img", {
                                        staticClass: "rounded-circle mr-3",
                                        staticStyle: {
                                          width: "40px",
                                          height: "40px"
                                        },
                                        attrs: {
                                          src: notification.user.avatar
                                            ? notification.user.avatar
                                            : "/images/jugador.png",
                                          alt: "user-image"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("div", [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "mb-0 font-weight-bold"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(notification.user.name) +
                                                " " +
                                                _vm._s(
                                                  notification.user.first_name
                                                )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "small",
                                          { staticClass: "text-muted" },
                                          [_vm._v(_vm._s(notification.message))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "small",
                                          { staticClass: "text-muted d-block" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatDate(
                                                  notification.created_at
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "posicon d-lg-block d-none" }, [
              _c(
                "div",
                { staticClass: "dropdown", class: { show: _vm.showdropdown } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "topbar-item",
                      attrs: {
                        "data-toggle": "dropdown",
                        "data-display": "static",
                        "aria-expanded": _vm.showdropdown
                      },
                      on: {
                        click: function($event) {
                          return _vm.showDropDown()
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "btn btn-icon w-auto btn-clean d-flex align-items-center pr-1 pl-3"
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-dark-50 font-size-base d-none d-xl-inline mr-3"
                            },
                            [
                              _vm._v(
                                "\n                                            " +
                                  _vm._s(_vm.username)
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "symbol symbol-35 symbol-light-success"
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "symbol-label font-size-h5 " },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-person-fill",
                                      attrs: {
                                        width: "20px",
                                        height: "20px",
                                        viewBox: "0 0 16 16",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu dropdown-menu-right",
                      class: { show: _vm.showdropdown },
                      staticStyle: { "min-width": "150px" }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/admin/profile" }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "svg-icon svg-icon-xl svg-icon-primary mr-2"
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "feather feather-user",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "20px",
                                    height: "20px",
                                    viewBox: "0 0 24 24",
                                    fill: "none",
                                    stroke: "currentColor",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("circle", {
                                    attrs: { cx: "12", cy: "7", r: "4" }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t\t\tEditar Perfil\n\t\t\t\t\t\t\t\t\t"
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              return _vm.logOut()
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "svg-icon svg-icon-xl svg-icon-primary mr-2"
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "feather feather-power",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "20px",
                                    height: "20px",
                                    viewBox: "0 0 24 24",
                                    fill: "none",
                                    stroke: "currentColor",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M18.36 6.64a9 9 0 1 1-12.73 0"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("line", {
                                    attrs: {
                                      x1: "12",
                                      y1: "2",
                                      x2: "12",
                                      y2: "12"
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t\t\tLogout\n\t\t\t\t\t\t\t\t\t"
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }