var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0"
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.registrarChofer.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "col-12 col-sm-12" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "origen" } }, [
                                    _vm._v("Nombre:")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.driver.nombre,
                                        expression: "driver.nombre"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "nombre",
                                      placeholder: "Ingrese su nombre completo",
                                      required: "",
                                      pattern: "[A-Za-z\\s]+",
                                      title:
                                        "El nombre solo debe contener letras y espacios."
                                    },
                                    domProps: { value: _vm.driver.nombre },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.driver,
                                            "nombre",
                                            $event.target.value
                                          )
                                        },
                                        function($event) {
                                          return _vm.validateName(
                                            $event,
                                            "nombre"
                                          )
                                        }
                                      ]
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "destino" } }, [
                                    _vm._v("Apellido:")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.driver.apellido,
                                        expression: "driver.apellido"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "apellido",
                                      placeholder: "Ingrese su Apellido",
                                      required: "",
                                      pattern: "[A-Za-z\\s]+"
                                    },
                                    domProps: { value: _vm.driver.apellido },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.driver,
                                            "apellido",
                                            $event.target.value
                                          )
                                        },
                                        function($event) {
                                          return _vm.validateName(
                                            $event,
                                            "apellido"
                                          )
                                        }
                                      ]
                                    }
                                  })
                                ])
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "col-12 col-sm-12" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "origen" } }, [
                                    _vm._v("Direccion:")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.driver.direccion,
                                        expression: "driver.direccion"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "direccion",
                                      placeholder: "Ingrese su direccion",
                                      required: ""
                                    },
                                    domProps: { value: _vm.driver.direccion },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.driver,
                                          "direccion",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "destino" } }, [
                                    _vm._v("Telefono:")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.driver.telefono,
                                        expression: "driver.telefono"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "telefono",
                                      placeholder: "ingrese su telefono",
                                      required: ""
                                    },
                                    domProps: { value: _vm.driver.telefono },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.driver,
                                            "telefono",
                                            $event.target.value
                                          )
                                        },
                                        function($event) {
                                          return _vm.validateNumber(
                                            $event,
                                            "telefono"
                                          )
                                        }
                                      ]
                                    }
                                  })
                                ])
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "col-12 col-sm-12" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "origen" } }, [
                                    _vm._v("C.I.:")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.driver.ci,
                                        expression: "driver.ci"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "ci",
                                      placeholder:
                                        "Ingrese su numero de carnet",
                                      required: ""
                                    },
                                    domProps: { value: _vm.driver.ci },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.driver,
                                            "ci",
                                            $event.target.value
                                          )
                                        },
                                        function($event) {
                                          return _vm.validateNumber(
                                            $event,
                                            "ci"
                                          )
                                        }
                                      ]
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "destino" } }, [
                                    _vm._v("Telf. Referencia:")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.driver.telreferencia,
                                        expression: "driver.telreferencia"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "telreferencia",
                                      placeholder:
                                        "ingrese su telefono de referencia"
                                    },
                                    domProps: {
                                      value: _vm.driver.telreferencia
                                    },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.driver,
                                            "telreferencia",
                                            $event.target.value
                                          )
                                        },
                                        function($event) {
                                          return _vm.validateNumber(
                                            $event,
                                            "telreferencia"
                                          )
                                        }
                                      ]
                                    }
                                  })
                                ])
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "col-12 col-sm-12" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "origen" } }, [
                                    _vm._v("N° Placa:")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.driver.placa,
                                        expression: "driver.placa"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "placa",
                                      placeholder: "Ingrese su numero de placa",
                                      required: ""
                                    },
                                    domProps: { value: _vm.driver.placa },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.driver,
                                          "placa",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "destino" } }, [
                                    _vm._v("Tipo Vehiculo:")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.driver.vehiculo,
                                        expression: "driver.vehiculo"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "vehiculo",
                                      placeholder: "ingrese su Vehiculo",
                                      required: ""
                                    },
                                    domProps: { value: _vm.driver.vehiculo },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.driver,
                                          "vehiculo",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "col-12 col-sm-12" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "origen" } }, [
                                    _vm._v("Color Vehiculo:")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.driver.color,
                                        expression: "driver.color"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "placa",
                                      placeholder:
                                        "Ingrese el color de su vehiculo"
                                    },
                                    domProps: { value: _vm.driver.color },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.driver,
                                          "color",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "destino" } }, [
                                    _vm._v("Licencia:")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.driver.licencia,
                                        expression: "driver.licencia"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "licencia",
                                      placeholder: "ingrese su Licencia"
                                    },
                                    domProps: { value: _vm.driver.licencia },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.driver,
                                          "licencia",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c("div", { staticClass: "col-12 col-sm-12" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "origen" } }, [
                                    _vm._v("Email:")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.driver.email,
                                        expression: "driver.email"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "email",
                                      placeholder:
                                        "Ingrese su correo electronico"
                                    },
                                    domProps: { value: _vm.driver.email },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.driver,
                                          "email",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-6 col-lg-6" }, [
                                  _c("label", { attrs: { for: "destino" } }, [
                                    _vm._v("Contraseña:")
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.driver.password,
                                        expression: "driver.password"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "password",
                                      id: "password",
                                      placeholder: "****"
                                    },
                                    domProps: { value: _vm.driver.password },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.driver,
                                          "password",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ])
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _vm._m(1)
                        ]
                      )
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "card card-custom gutter-b bg-transparent shadow-none border-0"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-header align-items-center  border-bottom-dark px-0"
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body"
                    },
                    [
                      _vm._v(
                        "\n                                            Agregar Nuevo Chofer\n                                        "
                      )
                    ]
                  )
                ])
              ]
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Registrar chofer")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }