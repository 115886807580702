var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
            _c(
              "div",
              { staticClass: "card card-custom gutter-b bg-white border-0" },
              [
                _vm._m(1),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c("form", [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("fieldset", { staticClass: "form-group mb-3" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.role_name,
                                expression: "role_name"
                              }
                            ],
                            staticClass:
                              "form-control round bg-transparent text-dark",
                            attrs: { type: "text", placeholder: "role name" },
                            domProps: { value: _vm.role_name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.role_name = $event.target.value
                              }
                            }
                          })
                        ])
                      ])
                    ])
                  ])
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-3" }, [
            _c(
              "div",
              {
                staticClass:
                  "card card-custom gutter-b bg-white border-0 roles-main"
              },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "ul",
                    {
                      staticClass: "nav flex-column nav-pills mb-3",
                      attrs: {
                        id: "v-pills-tab",
                        role: "tablist",
                        "aria-orientation": "vertical"
                      }
                    },
                    _vm._l(_vm.permissions, function(permission) {
                      return permission.parent_id == "0"
                        ? _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  id: permission.value + "-a-tab",
                                  "data-toggle": "pill",
                                  href: "#" + permission.value + "-a",
                                  role: "tab",
                                  "aria-controls": permission.value + "-a",
                                  "aria-selected": "false"
                                }
                              },
                              [_vm._v(_vm._s(permission.name))]
                            )
                          ])
                        : _vm._e()
                    }),
                    0
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-9" }, [
            _c(
              "div",
              {
                staticClass:
                  "card card-custom gutter-b bg-white border-0 roles-main"
              },
              [
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "div",
                    {
                      staticClass: "tab-content",
                      attrs: { id: "v-pills-tabContent" }
                    },
                    _vm._l(_vm.permissions, function(permission) {
                      return permission.parent_id == "0"
                        ? _c(
                            "div",
                            {
                              staticClass: "tab-pane fade",
                              attrs: {
                                id: permission.value + "-a",
                                role: "tabpanel",
                                "aria-labelledby": permission.value + "-a-tab"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "main-heading border-0" },
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticClass:
                                        "card-label mb-3 font-weight-bold text-body"
                                    },
                                    [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(permission.name) +
                                          "\n                    "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "p-2 bg-light mb-4" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "switch-h d-flex justify-content-between mb-0"
                                          },
                                          [
                                            _c(
                                              "h4",
                                              {
                                                staticClass:
                                                  "font-size-h4 text-dark mb-0"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                            " +
                                                    _vm._s(permission.name) +
                                                    "\n                          "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "\n                              custom-control\n                              switch\n                              custom-switch-info\n                              custom-switch\n                              custom-control-inline\n                              mr-0\n                            "
                                              },
                                              [
                                                _c("input", {
                                                  staticClass:
                                                    "custom-control-input",
                                                  attrs: {
                                                    type: "checkbox",
                                                    id:
                                                      "customdashboard" +
                                                      permission.id
                                                  },
                                                  domProps: {
                                                    value: permission.id,
                                                    checked: _vm.selectedPermission.includes(
                                                      permission.id
                                                    )
                                                      ? "checked"
                                                      : ""
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.check($event)
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("label", {
                                                  staticClass:
                                                    "custom-control-label mr-1",
                                                  attrs: {
                                                    for:
                                                      "customdashboard" +
                                                      permission.id
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.permissions, function(
                                      childpermission
                                    ) {
                                      return permission.id ==
                                        childpermission.parent_id
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "permissionsallow0"
                                            },
                                            [
                                              _vm.selectedPermission.includes(
                                                permission.id
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "toggle-swither px-5 py-3 border-top"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "\n                              switch-h\n                              d-flex\n                              justify-content-between\n                              mb-3\n                            "
                                                        },
                                                        [
                                                          _c(
                                                            "h4",
                                                            {
                                                              staticClass:
                                                                "font-size-h4 text-dark mb-0"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  childpermission.name
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "\n                                custom-control\n                                switch\n                                custom-switch-info\n                                custom-switch\n                                custom-control-inline\n                                mr-0\n                              "
                                                            },
                                                            [
                                                              _c("input", {
                                                                staticClass:
                                                                  "custom-control-input",
                                                                attrs: {
                                                                  type:
                                                                    "checkbox",
                                                                  id:
                                                                    "customView" +
                                                                    childpermission.id
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    childpermission.id,
                                                                  checked: _vm.selectedPermission.includes(
                                                                    childpermission.id
                                                                  )
                                                                    ? "checked"
                                                                    : ""
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.check(
                                                                      $event
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c("label", {
                                                                staticClass:
                                                                  "custom-control-label mr-1",
                                                                attrs: {
                                                                  for:
                                                                    "customView" +
                                                                    childpermission.id
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        : _vm._e()
                                    })
                                  ],
                                  2
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    }),
                    0
                  )
                ])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 d-flex justify-content-end" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary white",
                attrs: { type: "submit" },
                on: { click: _vm.addUpdateRolePermissions }
              },
              [_vm._v("\n            Guardar Cambios\n          ")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
        _c(
          "div",
          {
            staticClass:
              "\n              card card-custom\n              gutter-b\n              bg-transparent\n              shadow-none\n              border-0\n            "
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "card-header align-items-center border-bottom-dark px-0"
              },
              [
                _c("div", { staticClass: "card-title mb-0" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "card-label mb-0 font-weight-bold text-body"
                    },
                    [
                      _vm._v(
                        "\n                  Roles & Permisos\n                "
                      )
                    ]
                  )
                ])
              ]
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header border-0 align-items-center" },
      [
        _c(
          "h3",
          { staticClass: "card-label mb-0 font-weight-bold text-body" },
          [_vm._v("Roles")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }