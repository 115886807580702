<template>
    <div>
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card card-custom gutter-b bg-transparent shadow-none border-0">
                                    <div class="card-header align-items-center  border-bottom-dark px-0">
                                        <div class="card-title mb-0">
                                            <h3 class="card-label mb-0 font-weight-bold text-body">
                                            Listado de Conocimiento de Carga
                                            </h3>
                                        </div>
                                        <div class="icons d-flex">
                                            <!-- <button class="btn ml-2 p-0 kt_notes_panel_toggle" data-toggle="tooltip" title="" data-placement="right" data-original-title="Check out more demos" v-if="$parent.permissions.includes('account-manage')">
                                                <span class="bg-secondary h-30px font-size-h5 w-30px d-flex align-items-center justify-content-center  rounded-circle shadow-sm " v-on:click="
                                                            display_form = !display_form
                                                        ">
                                                    <svg width="25px" height="25px" viewBox="0 0 16 16" class="bi bi-plus white" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                                                    </svg>
                                                </span>
                                            </button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 ">
                                <div class="card card-custom gutter-b bg-white border-0">
                                    <div class="card-body">
                                        <div>
                                            <div class=" table-responsive" id="printableTable">

                                                <div id="productaccountTable_wrapper" class="dataTables_wrapper no-footer">

                                                <div class="dataTables_length" id="productaccountTable_length"><label>Mostrar
                                                <select name="productaccountTable_length" aria-controls="productaccountTable" class="" v-model="limit" v-on:change="fetchaccounts()">
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="200">200</option>
                                                <option value="500">500</option>
                                                <option value="1000">1000</option>
                                                </select> Entradas</label></div>

                                                <div id="productaccountTable_filter" class="dataTables_filter"><label>Buscar:<input type="search" class="" placeholder="" aria-controls="productaccountTable" v-model="searchParameter" @keyup="fetchaccounts()"></label></div>
                                                    <table id="productaccountTable" class="display dataTable no-footer" role="grid">
                                                        <thead class="text-body">
                                                            <tr role="row">
                                                                <th class="sorting" tabindex="0" aria-controls="productaccountTable" rowspan="1" colspan="1" aria-sort="ascending" aria-label="ID: activate to sort column descending"  @click="sorting('id')" :class="(this.$data.sortType == 'asc' || this.$data.sortType == 'ASC') && this.$data.sortBy == 'id'  ? 'sorting_asc' : (this.$data.sortType == 'desc' || this.$data.sortType == 'DESC') && this.$data.sortBy == 'id' ? 'sorting_desc' : 'sorting'">
                                                                    N°
                                                                </th>
                                                                <th class="sorting" tabindex="0" aria-controls="productaccountTable" rowspan="1" colspan="1" aria-label="account: activate to sort column ascending"  @click="sorting('nombre')" :class="(this.$data.sortType == 'asc' || this.$data.sortType == 'ASC') && this.$data.sortBy == 'name'  ? 'sorting_asc' : (this.$data.sortType == 'desc' || this.$data.sortType == 'DESC') && this.$data.sortBy == 'nombre' ? 'sorting_desc' : 'sorting'">
                                                                Fecha
                                                                </th>
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1">
                                                                  Destino
                                                                </th>
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1">
                                                                Pago Origen
                                                                </th>
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1">
                                                                  Pago Destino
                                                                </th>

                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action" >
                                                                    Total
                                                                </th>
                                                                 <th class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action" >
                                                                    A Cuenta
                                                                </th>
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action" >
                                                                    Chofer
                                                                </th>
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action" >
                                                                    Accion
                                                                </th>
                                                                <th class="no-sort sorting_disabled" rowspan="1" colspan="1" aria-label="Action" >
                                                                    Estado
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="kt-table-tbody text-dark">
                                                            <tr class="kt-table-row kt-table-row-level-0 odd" role="row" v-for="reconocimiento in reconocimientos" v-bind:key="reconocimiento.id">
                                                                <td class="sorting_1">
                                                                    00{{reconocimiento.id}}
                                                                </td>
                                                                <td>
                                                                    {{ reconocimiento.fecha }}
                                                                </td>
                                                                <td>
                                                                    {{ reconocimiento.destino }}
                                                                </td>
                                                                <td>{{ reconocimiento.pago_origen }}</td>
                                                                <td>
                                                                    {{ reconocimiento.pago_destino }}
                                                                </td>


                                                                <td>
                                                                    {{ reconocimiento.total }}
                                                                 </td>
                                                                <td>
                                                                    {{ reconocimiento.acuenta }}
                                                                </td>
                                                                <td>
                                                                    {{ reconocimiento.chofer.nombre }} {{ reconocimiento.chofer.apellido }}
                                                                </td>
                                                                <td v-if="reconocimiento.id > 0">
                                                                    <a href="javascript:void(0)" class="dropdown-item click-edit1" id="click-edit1" data-toggle="tooltip" title="" data-placement="right" data-original-title="Check out more demos" @click="editaccount(reconocimiento); display_form_type = 'type1'"><i class="fa fa-edit"></i></a>
                                                                    <a class="dropdown-item" href="#" @click="deleteaccount(reconocimiento.id)"><i class="fa fa-trash"></i></a>
                                                                    <a class="dropdown-item" href="#" @click="generatepdf(reconocimiento.id)"><i class="fa fa-file"></i></a>

                                                                </td>
                                                                <td>
                                                                    <a href="javascript:void(0)" class="dropdown-item click-edit2" id="click-edit2" data-toggle="tooltip" title="" data-placement="right" data-original-title="Check out more demos" @click="editestado(reconocimiento); display_form_type = 'type2'"><i class="fa fa-edit"></i></a>

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <ul class="pagination pagination-sm m-0 float-right">
                                                        <li v-bind:class="[{disabled: !pagination.prev_page_url}]"><a class="page-link" href="#" @click="fetchaccounts(pagination.prev_page_url)">Previous</a></li>

                                                        <li class="disabled"><a class="page-link text-dark" href="#">Page {{ pagination.current_page }} of {{ pagination.last_page }}</a></li>

                                                        <li v-bind:class="[{disabled: !pagination.next_page_url}]" class="page-item"><a class="page-link" href="#" @click="fetchaccounts(pagination.next_page_url)">Next</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    <!-- modal para la editacion de reconocimiento de carga
     -->
     <div class="offcanvas offcanvas-right kt-color-panel p-8 kt_notes_panel" v-if="display_form_type === 'type1'" :class="display_form ? 'offcanvas-on' : ''" id="click-edit1" v-show="display_form">
        <div class="offcanvas-header d-flex align-items-center justify-content-between pb-3">
            <h4 class="font-size-h4 font-weight-bold m-0">Editar Conocimiento de Carga</h4>
            <a href="#" class="btn btn-sm btn-icon btn-light btn-hover-primary kt_notes_panel_close" v-on:click="clearForm();  display_form = false;">
                <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                </svg>
            </a>
        </div>
        <form id="myform"  v-show="display_form">
            <div class="row">
                <div class="col-12">

                    <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card card-custom gutter-b bg-white border-0">
                                    <div class="card-body">
                                        <form>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                             <table class="table" style="float: left;">
                                                                <thead>
                                                                    <tr>

                                                                        <th width="5%">Fecha</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><input type="date" class="form-control" id="fecha" v-model="reconocimiento.fecha"></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                       </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <div class="col-md-12">
                                                                <label class="text-body">Choferes</label>
                                                                <fieldset class="form-group mb-3 d-flex">
                                                                    <select class="js-example-basic-single js-states form-control bg-transparent" v-model='reconocimiento.chofer_id' @change="appendChild($event,'select')">
                                                                            <option value="">Selecciona una opcion:</option>
                                                                            <option v-for='chofer in guia_dropdowns' :value='chofer.id'
                                                                                v-bind:selected="chofer.id"
                                                                                v-bind:key="chofer.id"
                                                                                v-if="chofer.status == 1"
                                                                                >{{ chofer.nombre }}
                                                                            </option>
                                                                    </select>

                                                                </fieldset>
                                                                <small class="form-text text-danger" v-if="errors.has('chofer_id')" v-text="errors.get('chofer')"></small>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Destino: </label>
                                                            <input type="text" class="form-control" id="destino" v-model="reconocimiento.destino" placeholder="ingrese el Destino">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <!-- start table  -->
                                            <div class="col-sm-12">
                                                <form @submit.prevent="agregarDetalle">
                                                    <label class="text-body">Guias</label>
                                                            <fieldset class="form-group mb-3 d-flex">
                                                                <select class="js-example-basic-single js-states form-control bg-transparent" id="my-select" v-model='nguia' @change="appendChild($event,'select')">
                                                                    <option value="">Selecciona una opcion:</option>
                                                                    <option v-for='listguia in listadoguias' :value='listguia.id'
                                                                        v-bind:selected="listguia.id"
                                                                        v-bind:key="listguia.id"
                                                                        v-if="listguia.status == 1"
                                                                        >00{{ listguia.numero }}
                                                                    </option>
                                                                </select>

                                                            </fieldset>
                                                            <!-- <small class="form-text text-danger" v-if="errors.has('chofer_id')" v-text="errors.get('chofer')"></small> -->


                                                    <button type="submit"  class="btn btn-primary">Agregar producto</button>
                                                </form>
                                                <table class="table table-striped table-bordered table-hover" width="100%">
                                                    <thead>
                                                        <tr class="text-center table-primary1">
                                                            <th width="6%">Consignatario</th>
                                                            <th width="5%">Guia N°</th>
                                                            <th width="5%">N° Bultos</th>
                                                            <th width="10%">Contenido</th>
                                                            <th width="3%">Importe</th>
                                                            <th width="3%">Accion</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                       <tr v-for="(producto, index) in reconocimiento.productos" :key="index">

                                                            <td class="text-center"> <input type="text" class="form-control" v-model="producto.consignatario"></td>
                                                            <td><input type="text" class="form-control" v-model="producto.guia"></td>
                                                            <td><input type="text" class="form-control" v-model="producto.bultos"></td>
                                                            <td class="text-center"><input type="text" class="form-control" v-model="producto.contenido"></td>
                                                            <td class="text-center"><input type="text" class="form-control" v-model="producto.importe"></td>
                                                            <td>

                                                                <a class="dropdown-item" href="#" @click="eliminarProducto(index)"><i class="fa fa-trash"></i></a>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <div class="col-sm-8">
                                                        <div class="form-group row">
                                                            <div class="col-sm-11"  style="border-right: 1px solid white;">
                                                            </div>
                                                            <div class="col-sm-1"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- en table  -->
                                            <div class="form-group">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Pago en Destino:</label>
                                                            <input type="text" class="form-control" id="total_pagar" v-model="reconocimiento.pago_destino" placeholder="Ingrese un monto">
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">Pago en Origen:</label>
                                                            <input type="text" class="form-control" id="acuenta" v-model="reconocimiento.pago_origen" placeholder="ingrese un monto">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Total Flete:</label>
                                                            <input type="text" class="form-control" id="total_pagar" v-model="reconocimiento.total" placeholder="Ingrese un monto">
                                                        </div>
                                                        <div class="col-6 col-lg-6">
                                                            <label for="destino">A cuenta:</label>
                                                            <input type="text" class="form-control" id="acuenta" v-model="reconocimiento.acuenta" placeholder="ingrese un monto">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Dias Entrega:</label>
                                                            <input type="text" class="form-control" id="total_pagar" v-model="reconocimiento.dias" placeholder="Ingrese cantidad de dias a entregar">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                </div>
            </div>
            <button type="button" @click="addUpdateaccount()" class="btn btn-primary">Guardar</button>
        </form>
    </div>
     <!-- finde modal  -->
     <!-- inicio de segundo modal  -->
     <div class="offcanvas offcanvas-right kt-color-panel p-8 kt_notes_panel" v-if="display_form_estado" :class="display_form_estado ? 'offcanvas-on' : ''">
        <div class="offcanvas-header d-flex align-items-center justify-content-between pb-3">
            <h4 class="font-size-h4 font-weight-bold m-0">Estado de Conocimiento de Carga</h4>
            <a href="#" class="btn btn-sm btn-icon btn-light btn-hover-primary kt_notes_panel_close" v-on:click="clearCanvas()">
                <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                </svg>
            </a>
        </div>
        <form id="myform">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-12 col-xl-12">
                            <div class="card card-custom gutter-b bg-white border-0">
                                <div class="card-body">
                                    <form>
                                        <div class="form-group">
                                            <div class="col-12 col-sm-12">
                                                <div class="row">
                                                    <div class="col-6 col-lg-6">
                                                        <label for="destino">Total Anterior:</label>
                                                        <input type="text" class="form-control" id="acuenta" v-model="a_cuenta" placeholder="ingrese un monto">
                                                    </div>
                                                    <div class="col-6 col-lg-6">
                                                        <label for="origen">Pago Actual:</label>
                                                        <input type="checkboox" class="form-control" id="total_pagar" v-model="pagoactual" >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12">
                                                    <div class="row">
                                                        <div class="col-6 col-lg-6">
                                                            <label for="origen">Estado:</label>
                                                            <input type="checkbox" class="form-control" id="total_pagar" v-model="estado" placeholder="Ingrese un monto">
                                                            <p v-if="estado">Estado pagado</p>
                                                        </div>

                                                    </div>
                                                </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" @click="Updatestatus()" class="btn btn-primary">Gaurdar</button>
        </form>
    </div>
     <!-- fin del segundo modal  -->
    </div>

    </template>
<script>
import ErrorHandling from "../../ErrorHandling";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from 'html2pdf.js';
import PrintGuia from "./PrintGuia.vue";
import axios from 'axios';
export default {
    components: {
        PrintGuia
    },
    data() {
        return {
            display_form: false,
            display_form_type: '',
            display_form_estado : 0,
            reconocimiento: {
                // numero: '',
                fecha: null,
                destino: '',
                pago_origen: '',
                pago_destino: '',
                total: '',
                acuenta: '',
                chofer_id : '',
                dias: '',
                productos: [],
                test: [],
            },
            consignatario: '',
            bultos : '',
            guia: '',
            contenido : '',
            guia_id: '',
            nguia: '',
            editando: false,

            pagoactual : '',
            estado: false,
            a_cuenta: '',

            viewToPrint: null,
            searchParameter: '',
            sortBy: 'id',
            sortType: 'ASC',
            limit: 10,
            error_message: '',
            edit: false,
            edit2: false,
            pagination: {},
            request_method: "",
            accounts: [],
            listadoguias: [],
            reconocimientos: [],
            guia_dropdowns:[],
            token: [],
            childToAppend:[],
            selected_account_id:"",
            errors: new ErrorHandling(),
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content")
        };
    },

    methods: {
        guiadropdowns(page_url) {
            this.$parent.loading = true;
            page_url = page_url || "/api/guias/create";
            axios.get(page_url, this.token).then(res => {
                this.guia_dropdowns = res.data.data;
                this.guia_id = res.data.numero.id;
                this.listadoguias = res.data.guia;
                console.log(res.data.data);
            }).finally(() => (this.$parent.loading = false));
        },

        fetchaccounts(page_url) {
            this.$parent.loading = true;
            let vm = this;
            page_url = page_url || "/api/admin/reconocimiento/list";
            var arr = page_url.split('?');

            if (arr.length > 1) {
                page_url += '&limit='+this.limit;
            }
            else{
                page_url += '?limit='+this.limit;
            }
            if(this.searchParameter != null){
                page_url += '&searchParameter='+this.searchParameter;
            }
            axios.get(page_url, this.token).then(res => {
                console.log(res.data.data);
                this.reconocimientos = res.data.data;
                vm.makePagination(res.data.meta, res.data.links);
            }).finally(() => (this.$parent.loading = false));
        },

        makePagination(meta, links) {
            let pagination = {
                current_page: meta.current_page,
                last_page: meta.last_page,
                next_page_url: links.next,
                prev_page_url: links.prev
            };

            this.pagination = pagination;
        },

        eliminarProducto(index) {
            this.reconocimiento.productos.splice(index, 1);
        },


        deleteaccount(id) {
            if (confirm('Estas Seguro de Eliminar el Registro?')) {
                this.$parent.loading = true;
                axios.delete(`/api/admin/reconocimiento/${id}/delete`,this.token)
                    .then(res => {
                        if (res.data.status == "Success") {
                            this.$toaster.success('Settings has been updated successfully')
                            this.fetchaccounts();
                        }

                    })
                    .catch(err => console.log(err))
                    .finally(() => (this.$parent.loading = false));
            }
        },
        // funcion para la impresion de pdf de un guia
        generatepdf(id){
            if (confirm('Solo podras imprimir una vez.')) {
                this.$parent.loading = true;
                axios.post(`/api/reconocimiento/imprimir`,{
                id: id
                }, {responseType: 'arraybuffer'})
                    .then(response => {
                        const blob = new Blob([response.data], { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(blob);
                        window.open(url, '_blank');

                    })
                    .catch(err => console.log(err))
                    .finally(() => (this.$parent.loading = false));

            }
        },
        // fin de la funcion para la impresion de guia

        // funcion para la impresion de pdf de reconocimiento de carga
        generatereconocimiento(id){
            this.$parent.loading = true;
                axios.post(`/api/reconocimiento/pdf`,{
                id: id
                }, {responseType: 'arraybuffer'})
                    .then(response => {
                        const blob = new Blob([response.data], { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(blob);
                        window.open(url, '_blank');

                    })
                    .catch(err => console.log(err))
                    .finally(() => (this.$parent.loading = false));

        },
        // fin de la funcion para la impresion de reconocimiento de carga

        // actualizar estodo
        Updatestatus(){
            this.$parent.loading = true;
            var url = '/api/admin/guias/listado';
            if (this.edit === false) {
                // Add
                this.request_method = 'post'
            } else {
                // Update
                var url = '/api/admin/reconocimiento/'+ this.reconocimiento.id +'/estado' ;
                this.request_method = 'put'
            }
            axios[this.request_method](url,
            {
                estado: this.estado ? 'pagado' : 'pendiente',
                total:this.pagoactual,

            },
            this.token)
                .then(res => {
                    if (res.data.status == "Success") {
                        this.display_form = 0;
                        this.$toaster.success('Settings has been updated successfully')
                        this.clearForm();
                        this.fetchaccounts();
                    } else {
                        this.$toaster.error(res.data.message)
                    }
                })
                .catch(error => {
					this.error_message = '';
					this.errors = new ErrorHandling();
					if (error.response.status = 422) {
						if(error.response.data.status == 'Error'){
							this.error_message = error.response.data.message;
						}
						else{
							this.errors.record(error.response.data.errors);
						}
					}
				}).finally(() => (this.$parent.loading = false));

        },
        // finde actualizar estado
        // guardar o actualizar
        addUpdateaccount() {
            this.$parent.loading = true;
            var url = '/api/admin/guias/listado';
            if (this.edit === false) {
                // Add
                this.request_method = 'post'
            } else {
                // Update
                var url = '/api/admin/reconocimiento/'+ this.reconocimiento.id +'/update' ;
                this.request_method = 'put'
            }

            axios[this.request_method](url,
            {
                fecha:this.reconocimiento.fecha,
                destino:this.reconocimiento.destino,
                pago_destino:this.reconocimiento.pago_destino,
                pago_origen:this.reconocimiento.pago_origen,
                total:this.reconocimiento.total,
                acuenta:this.reconocimiento.acuenta,
                chofer_id:this.reconocimiento.chofer_id,
                dias:this.reconocimiento.dias,
                productos:this.reconocimiento.productos,

            },
                this.token)
                .then(res => {
                    if (res.data.status == "Success") {
                        this.display_form = 0;
                        this.$toaster.success('Settings has been updated successfully')
                        this.clearForm();
                        this.fetchaccounts();
                    } else {
                        this.$toaster.error(res.data.message)
                    }
                })
                .catch(error => {
					this.error_message = '';
					this.errors = new ErrorHandling();
					if (error.response.status = 422) {
						if(error.response.data.status == 'Error'){
							this.error_message = error.response.data.message;
						}
						else{
							this.errors.record(error.response.data.errors);
						}
					}
				}).finally(() => (this.$parent.loading = false));

        },
        // fin proceso de registro

        editaccount(reconocimiento) {
            this.edit = true;
            this.display_form = 'type1'
            this.errors = new ErrorHandling();
            this.reconocimiento.id = reconocimiento.id;
            this.reconocimiento.fecha = reconocimiento.fecha;
            this.reconocimiento.destino = reconocimiento.destino;
            this.reconocimiento.pago_destino = reconocimiento.pago_destino;
            this.reconocimiento.pago_origen = reconocimiento.pago_origen;
            this.reconocimiento.total = reconocimiento.total;
            this.reconocimiento.acuenta = reconocimiento.acuenta;
            this.reconocimiento.chofer_id = reconocimiento.chofer_id;
            this.reconocimiento.dias = reconocimiento.dias;
            this.reconocimiento.test = reconocimiento.productos;
            this.$parent.loading = true;

            axios.get( `/api/admin/reconocimiento/${reconocimiento.id}/carga`, this.token).then(res => {
                this.reconocimiento.productos = res.data.data;
                console.log(res.data.data);
            }).finally(() => (this.$parent.loading = false));
            console.log(this.reconocimiento.acuenta);

        },
        editestado(reconocimiento) {
            this.edit = true;
            this.display_form_estado = 1
            this.errors = new ErrorHandling();
            this.reconocimiento.id = reconocimiento.id;
            this.a_cuenta = reconocimiento.acuenta;
            // this.$parent.loading = true;
            console.log(reconocimiento.acuenta);

        },

        clearForm() {
            this.display_form = 0;
            this.edit = false;
            this.reconocimiento.id = null;
            this.reconocimiento.fecha = "";
            this.reconocimiento.destino = "";
            this.reconocimiento.pago_destino = "";
            this.reconocimiento.pago_origen = "";
            this.reconocimiento.total = "";
            this.reconocimiento.acuenta = "";
            this.reconocimiento.chofer_id = "";
        },
        clearCanvas(){
            this.display_form_estado = 0;
            this.edit = false;
            this.a_cuenta = "";
        },

        sorting(sortBy){
            this.sortBy = sortBy;
            this.sortType = this.sortType == 'asc' || this.sortType == 'ASC' ? this.sortType='desc' : this.sortType = 'asc';
            this.fetchaccounts();
        },
         appendChild(e,select){
            this.findChild(e.target.value,select);
        },
        findChild(parent,select){
           for(var i = 0; i < this.childToAppend.length;i++){
                if(this.childToAppend[i].select == select){
                    this.childToAppend.splice(i);
                }
            }

            axios.get("/api/admin/account?parent_id="+parent, this.token).then(res => {
               this.selected_account_id = parent;
                if(res.data.data.length > 0){
                    var data = res.data.data;
                    this.childToAppend.push({data,select});
                }

            });

        },
        agregarDetalle(){
            const id = this.nguia;
            this.$parent.loading = true;
            axios.get(`/api/admin/reconocimiento/${id}`, this.token).then(res => {
                const nuevosProductos = res.data.data;
                nuevosProductos.forEach(producto => {
                    producto.reconocimiento_id = this.reconocimiento.id;
                });
                this.reconocimiento.productos = this.reconocimiento.productos.concat(nuevosProductos);

                console.log(res.data);
            }).finally(() => (this.$parent.loading = false));

        }
    },
    mounted() {

        var token = localStorage.getItem('token');
        this.token = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        this.fetchaccounts();
        this.guiadropdowns();
    }
};
</script>
